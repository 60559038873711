.ReactModal__Overlay {
    position: fixed !important;
    inset: 0 !important;
    background: var(--gradient-four) !important;
    backdrop-filter: blur(2px) !important;
    z-index: 9;
}

.ReactModal__Content {
    border: none !important;
    box-shadow: var(--boxShadow) !important;
    border-radius: 5px !important;
    padding: 0 !important;
}

.modal_container {
    /* border: 1px solid red; */
    display: flex;
    /* height: 33rem; */
    /* width: 90dvw; */
}

.modal_container-height {
    height: 95dvh;
    /* height: 35rem; */
}

.inputGrop-modalInp {
    /* border: 1px solid red; */
    position: relative;
    padding-bottom: 1.4rem;
}

.leftSide_modal {
    /* width: 40%; */
    background: var(--gradient-five);
    padding: 1rem 2rem;
    border-radius: 0px 15px 15px 0px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.rightSide_modal {
    /* width: 34rem; */
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 1.5rem;
    /* border: 1px solid red; */
}

.rightSide_modal-width {
    width: 34rem;
}

.input_group_pform-modalInp {
    flex-direction: column;
    gap: 1rem !important;
    width: 50%;
    max-width: 358px;
}

.modal_title {
    font-size: 1.1rem;
    font-weight: 500;
}

.content_left_modal_head {
    font-size: .85rem;
    width: 13rem;
    font-weight: 500;
}

.content_left_modal_desc {
    /* border: 1px solid; */
    width: 10rem;
    font-size: 0.8rem;
    color: var(--text_color_one);
}

.title_rightSide_modal {
    color: var(--text_color_three);
    font-weight: 500;
    /* border: 1px solid green; */
    display: flex;
    color: var(--text_color_three);
    flex-direction: column;
    gap: .3rem;
    font-size: 1.1rem;
}

.title_rightSide_modal>span {
    font-size: .8rem;
    font-weight: 400;
    color: var(--text_color_one);
}

.modal_selection_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid var(--background-colorThree);
    padding: 1rem;
    border-radius: 0px 0px 10px 10px;
    width: 90%;
}

.modal_selection_container_caseTwo {
    border: 1px solid var(--background-colorThree) !important;
    border-radius: 10px !important;
    margin-bottom: 1rem !important;
}

.modal_selection_container:nth-child(2) {
    /* border: 1px solid blue;  */
    margin-bottom: -1rem;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0;
}

.margin-top-modal {
    margin-top: 1rem !important;
}

.modal_selection_question {
    font-size: .85rem;
    font-weight: 500;
}

.small_textModal {
    font-size: .8rem;
}

.modal_options_container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.modal_options_container>button {
    border: 1px solid var(--border_color_two);
    width: 5.3rem;
    height: 1.8rem;
    background-color: #fff;
    font-size: .8rem;
    border-radius: 5px;
    cursor: pointer;
}

.closeImg_modal {
    position: absolute;
    right: 1rem;
    top: 1rem;
    width: .9rem;
    cursor: pointer;
}

.checkbox_container {
    display: flex;
}

.checkbox_container-modalinp {
    flex-direction: column;
    gap: 1rem;
}

.inputGrop-fmc-new {
    display: flex;
    align-items: center;
}

.modalBtn {
    height: 3rem;
    min-width: 11rem;
}

.button_container {
    background: #fff;
    display: flex;
    /* position: fixed; */
    gap: 2rem;
    bottom: 0;
    padding-bottom: 1rem;
    /* border: 1px solid red; */
}

.imp-msg {
    font-size: .75rem;
    color: var(--redTxt);
}

.position-msg {
    position: absolute;
    top: 5.4rem;
}

.positionUnique {
    position: absolute;
    top: -1rem;
}

.border-modalInp {
    width: 90%;
    border: 1px solid var(--border_color_four);
}

.msg-phoneNo {
    color: var(--text_color_one);
    font-weight: 400;
    font-size: .75rem;
    margin-top: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 22rem;
}

.leftSide_modal-mobile {
    padding-bottom: 0rem;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid var(--border_color_three);
    position: relative;
}

.leftSide_modal-mobile-vehicle {
    display: flex;
}

.head-mmv-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.leftSide_modal-mm {
    font-weight: 600;
    text-transform: capitalize;
}

.leftSide_modal-CarNum {
    font-weight: 500;
    width: fit-content;
    border-radius: 2px;
    display: flex;
    align-items: center;
}

.leftSide_modal-CarNum>span {
    /* border: 1px solid blue; */
    margin-right: 3px;
    letter-spacing: 0px;
    padding: 1px;
    font-size: .67rem;
    background-color: var(--primary);
    color: #fff;
    font-weight: 300;
    display: none;
    border-radius: 2px;
}

.leftSide_modal-vfr {
    display: flex;
    gap: 5px;
}

.leftSide_modal-vfr>p {
    color: var(--text_color_one);
    font-size: .7rem;
}

.leftSide_modal-vfr>p>span {
    color: #000;
}

.leftSide_modal-mobile-mmv-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.modal-inputs-parent {
    display: flex;
    /* border: 1px solid red; */
    flex-direction: column;
    gap: 1rem;
    padding: 0px;
    position: relative;
    /* overflow: auto; */
}

.leftSide_modal-mobile {
    display: none;
}

.form-login-head-kyc {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.login-child-form-kyc {
    width: 23rem;
}

.leftSide_modal-mobile-vehicle {
    border: 1px solid blue;
}

.leftSide_modal-mobile-vehicle-img {
    /* width: 5rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.coverage-content {
    /* border: 1px solid red; */
    background-color: #fff;
    padding: 1rem 1rem;
    margin: 0rem -1rem;
}

.coverage-content>p {
    color: var(--text_color_three);
    font-weight: 500;
    line-height: 24px;
}

.list-coverages-contnet>p {
    color: var(--text_color_three) !important;
    font-weight: 500;
}


/* RESPIONSIVENESS--------------------------------------------------------------------- */

/* @media (min-width: 400px) and (max-width: 610px) {
    .modal_container {
    }
} */

@media only screen and (max-width: 490px) {
    .modalBtn {
        min-width: unset;
        width: 50%;
    }
}

@media only screen and (max-width: 860px) {
    .modal_container {
        flex-direction: column;
    }
}

@media only screen and (max-width: 610px) {

    .breakupContainer_ackoKyc {
        display: none !important;
    }
    

    .modal_selection_container:nth-child(2) {
        margin-bottom: -1rem;
    }

    .modal-inputs-parent {
        gap: 1rem;
    }

    .rightContent_findMyCar {
        padding: 1rem;
    }

    .button_container {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 1rem 1rem;
        background-color: var(--background-colorTwo);
        border-radius: 20px 20px 0px 0px;
        gap: 1rem;
        z-index: 1;
    }

    .leftSide_modal-mobile {
        display: flex;
        gap: .5rem;
        justify-content: space-between;
    }

    /* .leftSide_modal-mobile>* {
        border: 1px solid red;
    } */

    .leftSide_modal-desktop {
        display: none;
    }

    .getRegNo_form_part {
        padding: 0rem;
    }

    .border-modalInp {
        display: none;
    }

    .msg-phoneNo {
        width: auto;
        position: relative;
    }

    .inputGrop-modalInp {
        padding-bottom: 0;
    }

    .input_group_pform-modalInp {
        width: 100%;
        max-width: none;
    }

    .rightSide_modal-width {
        width: 100%;
    }

    .content_left_modal_desc {
        font-size: .9rem;
    }

    .content_left_modal_head {
        font-size: .9rem;
    }

    .modal_selection_container {
        padding-right: 1rem !important;
        gap: 10px;
        padding: 15px;
        border: 1px solid var(--border_color_two);
        width: 100%;
        /* border-radius: 10px; */
    }

    .position-msg {
        top: 5px;
    }

    .title_rightSide_modal {
        display: none;
    }

    .closeImg_modal {
        top: -10rem;
        display: none;
    }

    .ReactModal__Content {
        border-radius: 5px !important;
    }

    .modal_container {
        height: 100dvh;
        /* width: 100dvw; */
    }

    .content_left_modal {
        display: flex;
        gap: .2rem;
        flex-direction: column;
    }

    .content_left_modal_head {
        width: auto;
    }

    .content_left_modal_desc {
        width: auto;
    }

    .width-title {
        /* border: 1px solid red; */
        width: 11rem;
    }

    .leftSide_modal {
        /* border: 1px solid red; */
        gap: .1rem;
        padding: 1rem 1rem;
    }

    .model_title_margin {
        margin-bottom: .5rem;
    }

    .bottomContentPadding {
        margin-top: .5rem;
        flex-direction: row;
        gap: .5rem;
    }

    .modal_title {
        font-size: 1rem;
    }

    .rightSide_modal {
        padding: 1rem 1rem;
        /* padding-bottom: 0rem; */
    }
}