.planError_container {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 0px 17px 0px #5086FF40;
    margin-top: 4rem;
    border-radius: 7px;
}

.planErrorBox1 {
    width: 52%;
    padding: 1rem;
}

.planErrorBox2 {
    background: #F1F5FF;
    width: 48%;
    border-radius: 7px;
    padding: 1rem;
    margin: 7px;
}

.planErrorBox2>div:nth-child(1) {
    font-size: 1.1rem;
    line-height: 1.6rem;
    line-height: 1.7rem;
    color: #1A1B1D;
    font-weight: bold;



}

.List_Data {
    color: #000000;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-top: 2rem;


}

.List_Data>div {
    margin-top: 1rem;
    margin-bottom: 1rem;
}


ul.numbered-list {
    counter-reset: li;
    list-style-type: none;
    font-size: 13px;
    line-height: 18px;
    padding-left: 6px;

    li {
      position: relative;   
      padding: 10px 0 10px 30px;
      
      &:before {
        content: counter(li);
        counter-increment: li;
        height: 20px;
        width: 20px;
        background: #5086FF;
        border-radius: 50%;
        color: white;
        text-align: center;
        position: absolute;      
        left: 0;
        top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
}

.casutionbox {
    display: flex;
    width: 3.5rem;
    height: 2.5rem;
    color: #FFFFFF;
    border-radius: 7px;
    font-family: Poppins;
    font-size: 8px;
    font-weight: 600;
    margin: 10px;
}

.LogoofCom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.LogoofCom>img {
    width: 20%;
    aspect-ratio: 3 / 2;
    object-fit: contain;
    /* border: 1px solid red; */
}

#card_Content {
    font-size: 13px;
    color: #60646A;
    font-family: Poppins;
    line-height: 24px;
    padding: 15px;

}

@media only screen and (min-device-width: 120px) and (max-device-width: 680px) {
    .planError_container {
        box-shadow: 0px 0px 17px 0px #5086FF40;
        display: inline-block;
        border-radius: 7px;
    }

    .planErrorBox1 {
        width: 100%;
        padding: 1rem;
    }

    .planErrorBox2 {
        background: #F1F5FF;
        width: 100%;
        border-radius: 7px;
        padding: 1rem;
        margin: 7px;
    }
}