.aboutus-container {
    /* border: 1px solid red; */
    padding: 3rem 4rem;
    position: relative;
    background-image: url("../../../Assets/Images/Background.jpg");
    background-repeat: no-repeat;
    background-size: 100% 20rem;
}

.hero-container-aboutus {
    /* border: 1px solid blue; */
    background-color: #fff;
    box-shadow: var(--boxShadow);
    border-radius: 15px;
    display: flex;
    /* align-items: center; */
    gap: 2rem;
    padding: 1.5rem;
}

.hero-parts-aboutus {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    height: 511px;
    /* width: 50%; */
}

/* .clients-container-aboutus{
    position: absolute;

} */

.hero-container-aboutus>* {
    flex: 1 1;
    /* border: 1px solid green; */
}

.hero-parts-aboutus-head {
    font-size: 1.5rem;
    color: var(--text_color_three);
}

.hero-parts-aboutus-head>span {
    font-weight: 700;
}

.hero-parts-aboutus-description {
    font-weight: 600;
    font-size: 1rem;
    color: var(--text_color_one);
}

.hero-parts-aboutus-summary {
    color: var(--text_color_one);
    font-size: 0.9rem;
    color: var(--text_color_one);
}

.clients-container-aboutus {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: flex-start;
    padding: 1rem 0rem;
    align-items: center;
    position: absolute;
    bottom: 0;
    /* border: 1px solid red; */
}

.clients-photos-aboutus {
    display: flex;
    position: relative;
}

.img-circle-aboutus {
    height: 3.3rem;
    width: 3.3rem;
    background-color: var(--background-color-peach);
    border-radius: 50%;
    border: 1px solid #f663;
    position: relative;
    overflow: hidden;
}

.img-circle-aboutus>img {
    width: 2.5rem;
    position: relative;
    top: 0.5rem;
    /* object-fit: contain; */
}

.img-2-abtus {
    right: 1rem;
}

.img-3-abtus {
    right: 2rem;
}

.img-4-abtus {
    right: 3rem;
}

.clients-numbers-aboutus {
    /* border: 1px solid blue; */
    position: relative;
    right: 1.5rem;
}

.clients-numbers-aboutus>p {
    color: var(--text_color_one);
}

.hero-parts-aboutus>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.leadership-container-aboutus {
    /* border: 1px solid red; */
    margin: 0rem -4rem;
    /* margin-top: 6rem; */
    background-color: var(--background-color);
    padding: 3rem 4rem;
}

.leadership-parent-container-aboutus {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.leadership-profile-container {
    /* border: 1px solid purple; */
    display: flex;
    justify-content: space-between;
    gap: 2rem;
}

.leader-profile-square {
    /* border: 1px solid green; */
    height: 25rem;
    width: 15rem;
    position: relative;
}

.square-ui {
    height: 15rem;
    border: 3px solid #fff;
    width: 80%;
    left: 0;
    right: 0;
    bottom: 5rem;
    /* margin-left: auto;
    margin-right: auto; */
    position: absolute;
    background: var(--gradient-ten);
    border-radius: 20px;
}

.social-icons-abtus {
    border: 3px solid #fff;
    bottom: -1.3rem;
    position: absolute;
    width: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1.3rem;
    height: 3rem;
    background-color: var(--background-color-peach);
    border-radius: 10px;
    gap: 1rem;
}

.social-icon-square {
    /* border: 1px solid red; */
    width: 1.8rem;
    height: 1.8rem;
}

.social-icon-square>a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon-square>a>img {
    width: 100%;
    height: 100%;
}

.fbbb>a>img {
    width: 90%;
    height: 90%;
    position: relative;
    top: 2px;
}

.square-ui>img {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 1.5rem;
}

.name-designation-abtus {
    /* border: 1px solid black; */
    position: absolute;
    bottom: 0;
    width: 80%;
    left: 0;
    right: 0;
    /* margin-left: auto;
    margin-right: auto; */
}

.abtus-name {
    color: var(--text_color_three);
    font-weight: 600;
    font-size: 0.95rem;
}

.abtus-desg {
    color: var(--text_color_one);
    font-weight: 500;
}

.cards_container_parent-abtus {
    /* border: 1px solid red; */
    padding: 1.5rem 0rem;
    padding-bottom: 0rem;
}

.card_div_container-abtus {
    padding: 1.5rem 0rem;
    padding-bottom: 0rem;
    /* border: 1px solid blue; */
}

.card_container_child-abtus {
    /* border: 1px solid green; */
    /* justify-content: space-around; */
    justify-content: flex-start;
    padding-bottom: 3rem;
}

.card_landing-aboutus {
    width: 29%;
    min-width: 17rem;
}

.culture-container-aboutus {
    background: var(--gradient-eleven);
}

.misson-container-aboutus {
    position: relative;
    /* border: 1px solid blue; */
    background: var(--gradient-twelve);
}

.misson-left-content {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.culture-img-container {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
}

.block-culture-img {
    /* border: 1px solid blue; */
    height: 360px;
    /* border-radius: 30px; */
}

.block-culture-img-two {
    height: 250px;
    width: 30%;
}

.block-one-culture {
    width: 30%;
}

.block-two-culture {
    width: 30%;
}

.block-three-culture {
    width: 40% !important;
}

.block-one-culture-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.block-three-culture-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 30px;
}

.block-two-culture {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;
}

.block-two-culture>* {
    height: 50%;
    /* border: 1px solid green; */
}

.block-threee-culture>* {
    height: 100%;
}

.block-two-culture-block {
    display: flex;
    gap: 2rem;
}

.block-two-culture-block>* {
    flex: 1 1;
    /* border: 1px solid blue; */
    background-color: #fff;
    border-radius: 30px;
}

.block-two-culture-block-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.culture-img-container-margin {
    margin-bottom: 1rem;
}

.block-two-culture-block-2 {
    height: 100%;
}

.rocket-mission {
    width: 28rem;
    position: absolute;
    right: 0;
}

.experts-container-aboutus {
    /* border: 1px solid blue; */
    padding: 1rem 0rem;
    padding-top: 3rem;
}

.photos-container-experts {
    /* border: 1px solid red; */
    position: relative;
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    flex-wrap: wrap;
}

.photos-circle-experts {
    height: 10.5rem;
    width: 10.5rem;
    border-radius: 50%;
    /* border: 1px solid blue; */
    overflow: hidden;
    /* position: absolute; */
}

.expert-img {
    height: 100%;
    width: 100%;
    transition: all 0.2s ease-in;
    object-fit: cover;
    filter: grayscale(100%);
    cursor: pointer;
}

.expert-img:hover {
    filter: grayscale(0%);
}

.expert-circle-img {
    position: absolute;
    left: 0.67rem;
    height: 10.5rem;
    width: 10.5rem;
    z-index: -1;
    bottom: 0.4rem;
}

.experts-circle-container {
    /* border: 1px solid green; */
    position: relative;
}



.photos-section-expert {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    margin-top: 2rem;
    /* border: 1px solid red; */
    padding-top: 1rem;
    /* height: fit-content; */
    padding-bottom: 7rem;
}

.tech-experts-container {
    margin: 0rem -4rem;
    padding: 0rem 4rem;
    position: relative;
    margin-bottom: 2rem;
    /* border: 10px solid orange; */
}

.borderCircle {
    position: absolute;
    right: 0;
    z-index: -1;
    top: -20rem;
}

.btn-view-all {
    width: 150px;
    height: 44px;
    margin: auto;
    border-radius: 3px;
}


/* RESPONSIVENESSS----------------------------------------------------------------------- */

@media only screen and (max-width: 940px) {
    .culture-img-container {
        flex-wrap: wrap;
    }

    .leadership-profile-container {
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 770px) {
    .photos-section-expert {
        gap: 10rem;
    }

    .hero-container-aboutus {
        flex-direction: column;
    }
}

@media only screen and (max-width: 610px) {

    .clients-container-aboutus {
        position: relative;
    }

    .photos-section-expert {
        padding-bottom: 0;
    }

    .photos-section-expert {
        gap: 3rem;
    }

    .experts-circle-container-one {
        top: 0;
    }

    .experts-circle-container-two {
        right: 0;
        top: 0;
    }

    .experts-circle-container-three {
        right: 0;
        /* top: 0; */
        bottom: 0;
    }

    .experts-circle-container-four {
        left: 0;
        bottom: 0;
    }

    .experts-circle-container-five {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .experts-circle-container {
        /* border: 1px solid green; */
        position: absolute;
    }

    .photos-container-experts {
        /* border: 1px solid red; */
        height: 550px;
    }

    .photos-circle-experts {
        width: 9rem;
        height: 9rem;
    }

    .expert-circle-img {
        width: 9rem;
        height: 9rem;
        bottom: 0.5rem;
    }

    .misson-container-aboutus {
        padding-bottom: 7rem;
    }

    .borderCircle {
        width: 13rem;
        top: -7rem;
    }

    .block-three-culture {
        width: 100% !important;
    }

    .block-one-culture {
        width: 100%;
    }

    .block-culture-img-two {
        width: 100%;
    }

    .block-two-culture {
        width: 100%;
    }

    .block-two-culture>* {
        width: 100%;
    }

    .culture-img-container {
        flex-direction: column;
    }

    .card_landing-aboutus {
        width: 100%;
    }

    .aboutus-container {
        padding: 1rem;
        overflow: hidden;
    }

    .leadership-container-aboutus {
        margin-top: 2rem;
        /* padding-bottom: 4rem; */
    }

    .rocket-mission {
        width: 12rem;
        bottom: -7rem;
        right: 2rem;
    }

    .misson-left-content {
        width: 100%;
    }
}

@media only screen and (min-device-width: 611px) and (max-device-width: 1600px) {

    .position-bottom-circle {
        top: 5rem;
    }
}