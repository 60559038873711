.planSelectorContainer {
  height: 3rem;
  display: flex;
  position: sticky;
  background: #fff;
  margin: 1rem 0rem;
  top: 0px;
  z-index: 2;
  gap: 1rem;
  justify-content: space-between;
}

.plan-detail-parent {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.planSelectorContainer-bike {
  justify-content: flex-start;
}

.idv-topcontent-container {
  width: 25%;
  border-radius: 5px;
  border: 1px solid var(--background-colorThree);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
}

.prem-breakup-container-mob {
  display: none;
}

.policyTypeOption-container {
  /* border: 1px solid red; */
  display: flex;
  width: 75%;
}

.planSelectorNonSticky {
  height: 3rem;
  display: flex;
}

.plancard_main_container {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 2%;
}

.plancard_card {
  border: 1px solid var(--border_color_two);
  border-radius: 10px;
  width: 32%;
  max-width: 290px;
  min-width: 230px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* height: 100%; */
}

.plancard_card>.padding_breakup_container {
  flex-grow: 1;
}

.bottomOptions_planCard {
  flex-shrink: 0;
}

.inspection_tab {
  background-color: #6B7385;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
  border-radius: 5px;
}

.cpaForHdfc {
  background-color: #6B7385;
  color: #fff;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 10px;
  border-radius: 5px;
}

.inspection_tab-green {
  background-color: var(--greenTwo);
}

.inspection_tab>p {
  font-size: .75rem;
}

.inspection_tab>img {
  width: 1.2rem;
}

.padding_breakup_container {
  padding: .7rem .7rem;
  display: flex;
  border-radius: 10px 10px 0px 0px;
  background-color: #fff;
  /* background: var(--gradient-three); */
  flex-direction: column;
  gap: 10px;
}

.plancard_card_img_holder {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin: auto; */
}

.plancard_card_img_holder>img {
  width: 40%;
  aspect-ratio: 3 / 2;
  object-fit: contain;
}

.premiumContainer_pc {
  width: 100%;
  padding: 5px 5px;
  /* border: 1px solid red; */
  background-color: var(--background-colorNine);
  display: flex;
  border-radius: 5px;
  flex-direction: column;
  gap: 10px;
}

.idv_title_pc {
  color: var(--text_color_one);
  display: flex;
  font-size: .9rem;
  font-weight: 500;
  padding: 0px 5px;
  gap: .2rem;
  /* border: 1px solid red; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.idv_title_pc-mobile {
  align-items: flex-start;
}

.idv_title_pc>span {
  /* border: 1px solid blue; */
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .9rem;
  font-weight: 500;
  color: var(--text_color_three);
}

.buyNowBtn_pc2 {
  background: var(--primary);
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all .1s ease;
  font-weight: 700;
}

.buyNowBtn_pc {
  background: var(--primary);
  border: none;
  outline: none;
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
  height: 3.5rem;
  cursor: pointer;
  font-size: 1.2rem;
  transition: all .1s ease;
  font-weight: 700;
}

.buyNowBtn_pc>span {
  /* color: ; */
  font-size: 0.8rem;
  font-weight: 500;
}

.buyNowBtn_pc-mobile {
  display: none;
}

.buyNowBtn_pc:hover {
  background-color: #2C5ECD;
}

.breakup_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.planHeadValueContainer {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.planHead>p {
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--text_color_one);
}

.planHead>p>span {
  color: var(--text_color_three);
  font-weight: 500;
}

.bold_premium {
  font-weight: 700 !important;
}

.card_msg_pc {
  font-size: 0.8rem;
  color: var(--text_color_three);
}

.bottomOptions_planCard {
  /* background-color: var(--background-colorThree); */
  /* background-color: var(--primary); */
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  height: 3rem;
  border-top: 1px solid var(--background-colorThree);
  padding: 10px 0px;
  border-radius: 0px 0px 10px 10px;
}

.bottomOptions_planCard>* {
  flex: 1 1;
}

.bottomOptions_planCard>.option_div_pc:last-child {
  display: none;
}

.option_div_pc>p {
  height: 100%;
  /* color: #000 !important; */
  color: var(--primary) !important;
  font-size: 0.75rem !important;
  font-weight: 500;
  width: 100%;
  gap: 10px;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.option_div_pc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
}

.option_div_pc_border {
  /* border-right: 1px solid #fff; */
  color: #fff;
}

.modal-plandetails {
  width: 58rem;
  justify-content: space-between;
}

.ins-card-planDetails {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px;
  /* border: 1px solid red; */
  border-radius: 5px;
  background-color: #fff;
  gap: 2rem;
  margin-bottom: 1rem;
}

.ins-card-planDetails>img {
  width: 5rem;
  aspect-ratio: 3 / 2;
  object-fit: contain;
}

.ins-card-planDetails>p {
  font-weight: 700;
  font-size: 0.9rem;
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.ins-card-planDetails>p>span {
  color: var(--text_color_one);
  font-size: 0.75rem;
  font-weight: 400;
}

.leftSide_modal-plandetails {
  display: flex;
  flex-direction: column;
  gap: 0rem;
  /* width: 35%; */
  padding: 1.5rem;
}

.breadCrumb-planDetails {
  /* border: 1px solid red; */
  height: 3.3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  font-size: 0.8rem;
  width: 16.3rem;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  border-radius: 5px;
}

.breadCrumb-planDetails-active {
  box-shadow: var(--boxShadowTwo);
  background: #fff;
}

.rightSide_modal-plandetails {
  /* border: 1px solid red; */
  /* width: 60%; */
  padding: 1.5rem;
  padding-right: 2.3rem;
}

.coveredDetails-plandetails {
  border: 1px solid var(--border_color_two);
  padding: 1rem 1.5rem;
  border-radius: 5px;
  width: 34rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  /* height: 21rem; */
  margin-bottom: 0.5rem;
  overflow: auto;
}

.coveredDetails-plandetails-breakup {
  gap: .5rem;
}

.details-covered {
  /* border: 1px solid blue; */
  display: flex;
  border-bottom: 1px solid var(--border_color_three);
  padding-bottom: 13px;
  justify-content: space-between;
  align-items: center;
}

.details-covered:last-child {
  border: none;
  margin-bottom: 0;
}

.details-covered>p {
  font-size: 0.85rem;
  color: var(--text_color_one);
}

.details-covered-breakup-section {
  border-bottom: 0;
  padding-bottom: 0;
}

.details-covered-breakup-section-highlighted {
  border: 1px solid var(--border_color_three) !important;
  margin-bottom: 1rem;
  background-color: var(--background-colorSix);
  padding: 5px 10px;
  border-radius: 5px;
}

.details-covered-breakup-section-highlighted>p {
  color: var(--text_color_one);
  font-weight: 600;
}

.title-rightside-plandetails {
  font-size: 0.9rem;
  font-weight: 500;
  /* margin-top: 1rem; */
}

.closeImg_modal-plandetails {
  right: 1.4rem;
  top: 1.4rem;
}

.section-planDetails {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.amt-breakup-plandetails {
  color: var(--text_color_three) !important;
  /* font-weight: 500; */
}

.confirmDetailContainer-popUp {
  /* border: 1px solid red; */
  width: 50rem;
  /* height: 100dvh; */
  padding: 1rem;
  position: relative;
}

.head-confirmDetailContainer-popUp {
  /* border: 1px solid red; */
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}

.head-confirmDetailContainer-popUp>p {
  color: #000;
  font-size: 1.05rem;
  font-weight: 500;
  /* margin-bottom: 1rem; */
}

.head-confirmDetailContainer-popUp>img {
  cursor: pointer;
}

.confirm-details-parent {
  height: 100dvh;
  position: relative;
}

.form-cnfrmDetails-popup {
  border-radius: 5px;
  border: 1px solid var(--background-colorThree);
  padding: 0.5rem;
  height: 77dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
  position: relative;
}

.form-cnfrmDetails-inputGrp {
  /* border: 1px solid red; */
  padding: 10px;
  display: flex;
  gap: 2rem;
  align-items: flex-start;
}

.od-msg {
  color: var(--text_color_three);
  font-size: .65rem;
}

.form-btn-inputGrp-div {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 0.5rem;
}

.form-btn-inputGrp {
  gap: 1rem;
  /* border: 1px solid red; */
  display: flex;
}

.form-cnfrmDetails-inputGrp>* {
  flex: 1 1;
  /* border: 1px solid blue; */
}

.cnfrmDetails-inputGrp-container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}


.Ncb_alert_tooltip {
  display: flex;
  gap: 0.4rem;
}

.tooltip-container>img {
  width: 1rem;
  height: 1rem;
  margin-top: 2.5px;
  flex-shrink: 0;
  cursor: pointer;
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: 18.122rem;
  height: 2.393rem;
  flex-shrink: 0;
  background-color: #FF464D;
  text-align: center;
  color: #FFF;
  font-family: Poppins;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 254.545% */
  border-radius: 5px;
  padding: 4px 0;
  position: absolute;
  z-index: 1;
  align-items: end;
  top: 115%;
  /* Position the tooltip below the text */
  left: 800%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-container .tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  /* Arrow pointing upwards */
  left: 8.5%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #FF464D transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}



/* .calender-cnfrm-popup {
  width: 150px;
} */

.label-popUp {
  font-size: 0.8rem;
  font-weight: 500;
}

.label-popUp-two {
  margin-bottom: 0.5rem;
}

.customDatePicker-popUp {
  margin-top: 0;
  width: 150px;
  font-size: .9rem;
  background-color: var(--border_color_three);
  border: 1px solid var(--border_color_two);
  color: var(--primary);
}

.dropdown-popUp {
  width: 150px;
  height: 30px;
}

.dropDownHead-popUp {
  display: flex;
  justify-content: space-between;
  height: 30px;
  align-items: center;
  padding: 0rem 1rem;
  border-radius: 5px;
  background-color: var(--border_color_three);
  cursor: pointer;
}

.input-dp-popup {
  border: 0px;
  outline: 0px;
  font-size: 0.9rem;
  background-color: var(--border_color_three);
  width: 100%;
  color: var(--primary);
  cursor: pointer;
}

.dpGrp-popUp {
  display: flex;
  height: 100%;
  width: 100%;
  gap: 1rem;
}

.dpGrp-popUp-gap {
  gap: 2rem;
}

.dpGrp-popUp>* {
  flex: 1 1;
}

#arrowInGray {
  width: 0.8rem;
}

.polType-btn-popUp {
  border: 1px solid var(--text_color_two);
  min-width: 92px;
  font-size: .9rem;
  padding: 0px 15px;
  height: 30px;
  background-color: #fff;
  color: var(--text_color_two);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
}

.polType-btn-popUp-active {
  background-color: var(--border_color_three);
  border: 1px solid var(--border_color_two);
  color: var(--primary);
}

.checkbox_container-popUp {
  padding: 10px;
  display: flex;
  align-items: center;
}

.hr-popUp {
  border: 1px solid var(--border_color_three);
  margin: 10px 0px;
}

.input_group_editFormThree {
  /* border: 1px solid red; */
  height: 10rem;
}

.btnContainer_pform-cnfrmDetails {
  /* width: 100%; */
  /* border: 1px solid red; */
  padding: 1rem;
  background-color: #fff;
  padding: 0rem 1rem;
  padding-bottom: 10px !important;
  position: sticky;
  bottom: 0;
  padding-top: 1rem;
}

.msgIn-red {
  font-size: 0.75rem;
  color: red;
  font-weight: 300;
}

.inputGrop_caseThreee {
  position: relative;
  bottom: 10px;
}

.breakup-head-planDetails {
  /* border: 1px solid red; */
  padding-bottom: 3px;
}

.breakup-head-planDetails>p:first-child {
  color: var(--text_color_three);
  font-weight: 600;
}

.breakup-head-planDetails>p:last-child {
  color: var(--text_color_one);
  font-weight: 600;
}

.breakup-head-planDetails>p:last-child>span {
  color: var(--text_color_three);
}

.btnContainer_pform-cnfrmDetails-Mobile {
  display: none !important;
}

.covered-details-container {
  width: 33rem;
  height: 27rem;
  /* border: 1px solid red; */
  overflow: auto;
  padding-right: 1rem;
  margin-right: 1rem;
}

.covered-details-container-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 5px 0px;
  color: var(--text_color_three);
}

.covered-details-container-desc {
  color: var(--text_color_one);
  font-weight: 400;
}

.list-coverages- {
  /* border: 1px solid blue; */
  margin: 14px 0px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.title-list-coverages {
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 8px;
}

.title-list-notCovered>p {
  color: var(--redTxt_two) !important;
}

.title-list-coverages>p {
  color: var(--greenText);
  font-weight: 500;
  font-size: .85rem;
}

.list-coverages->p {
  color: var(--text_color_one);
  font-weight: 400;
  padding-left: 20px;
}

.edit-tabs-container-head-bg>img {
  position: absolute;
  width: 1rem;
  top: 1.4rem;
  right: 1rem;
}

.edit-tabs-container {
  position: relative;
}

.verify-tab-cd {
  /* border: 1px solid red; */
  padding: 15px 3px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 3px solid var(--border_color_three);
}

.verify-tab-cd:last-child {
  border-bottom: 0;
}

.verify-tab-cd>* {
  flex: 1 1;
}

.verify-tab-cd-child>p:first-child {
  color: var(--text_color_one);
  font-weight: 400;
  font-size: 12px;
}

.verify-tab-cd-child>p:last-child {
  color: #000;
  margin-top: 3px;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

.btn-vd-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 1rem;
}

.btn-vd {
  border: 0;
  outline: 0;
  height: 44px;
  border-radius: 5px;
}

.btn-verifyDetails-p {
  background-color: var(--primary);
  color: #fff;
}

.btn-verifyDetails-s {
  background-color: var(--secondry);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.selectPlan-btn {
  border: 1px solid var(--background-colorThree);
  position: absolute;
  right: 1rem;
  top: -.9rem;
  cursor: pointer;
  width: 7.5rem;
  height: 28.81px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  background: #fff;
}

.radio-container-package-plan {
  min-width: 60px;
}

.package-container {
  width: 450px;
  padding: 1rem;
  /* min-height: fit-content; */
  /* max-height: 100dvh; */
  max-height: 100dvh;
  height: auto;
  position: relative;
}

.head-package-modal {
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  gap: 10px;
  margin-bottom: 10px;
  z-index: 1;
  padding-top: .6rem;
  margin-top: -1rem;
  background-color: #fff;
}

.head-package-modal>div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.head-package-modal>div>p {
  font-size: 1rem;
  font-weight: 500;
}

.plan-package-head {
  /* border: 1px solid blue; */
  display: flex;
  cursor: pointer;
  justify-content: space-between;
}

.radio-container-package-popup {
  display: flex;
  /* border: 1px solid red; */
  align-items: center;
  gap: 10px;
}

.radio-container-package-popup>p {
  font-size: .9rem;
  font-weight: 500;
  color: var(--text_color_three);
}

.radio-container-package-plan>p:first-child {
  color: var(--text_color_three);
  font-size: .9rem;
  font-weight: 700;
}

.radio-container-package-plan>p:last-child {
  color: var(--text_color_one);
  font-size: .7rem;
}

.plan-package-container {
  border: 1px solid var(--border_color_two);
  padding: .6rem;
  border-radius: 5px;

}

.active-plan-package-container {
  background: var(--background-colorNine);
}

.package-container-modal {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

.addons-plan-tab {
  display: flex;
  padding-left: 35px;
  gap: 10px;
}

.plan-breakup-package {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
}

.packahe-modal-btn-container {
  display: flex;
  gap: 1rem;
  background-color: #FFF;
  position: sticky;
  bottom: 0;
  padding: 1rem 0rem;
}

.fixed-modal-btn-container {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 1rem;
}

.input_group_new {
  /* border: 1px solid red; */
  flex-direction: column;
  width: 47%;
  gap: 1.5rem !important;
}

.packahe-modal-btn-container>* {
  flex: 1 1;
}

.btn-packageModals {
  height: 50px;
}

.noSelectedPlan {
  border-radius: 5px;
  border: 1px solid var(--Blue-5, #B1C9FF);
  background: #5087ffc1;
  color: #B1C9FF;
}

.noSelectedPlan:hover {
  border-radius: 5px;
  border: 1px solid var(--Blue-5, #B1C9FF);
  background: #5086FF;
  color: #b1c8ffe6;
}

.zero-dep-tab-card {
  /* border: 1px solid red; */
  background-color: var(--aqua);
  display: flex;
  align-items: center;
  padding: 10px 10px;
  gap: 3px;
  border-radius: 5px;
  justify-content: space-between;
}

.zero-dep-tab-card>p {
  font-size: 10px;
}

.zero-dep-tab-card>div>p {
  font-size: 11px;
  color: var(--primary);
  font-weight: 400;
}

.zero-dep-tab-card>div {
  display: flex;
  gap: 3px;
  align-items: center;
}

.dp-main-container {
  min-width: 12rem;
  /* border: 1px solid blue; */
  position: relative;
  display: flex;
  flex-direction: column;
}

.dp-main-head {
  border: 1px solid var(--background-colorThree);
  width: 100%;
  position: relative;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.dp-main-head>p:first-child {
  color: var(--text_color_one);
  font-size: .8rem;
  font-weight: 600;
}

.dp-main-head>p:nth-child(2) {
  color: var(--primary);
  font-size: .61rem;
}

.dp-main-head>img {
  width: .95rem;
  position: absolute;
  right: 10px;
  top: 25px;
}

.dp-main-options {
  border: 1px solid var(--background-colorThree);
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 3.4rem;
}

.dp-main-options>button {
  border: 0;
  outline: 0;
  background-color: #fff;
  padding: 5px 10px;
  text-align: left;
  cursor: pointer;
  font-size: .8rem;
}

.dp-main-options>button:hover {
  background-color: var(--secondry);
}

.dp-main-options>button:last-child {
  border-bottom: 0px;
}

.premiumContainer_mobile-container {
  display: none;
}

.arrowRight-icon-mob {
  display: block;
}

.selectPlan-tab-mobile {
  display: flex;
  border: 1px solid var(--background-colorThree);
  width: 100%;
  padding: 10px;
  justify-content: space-between;
  border-radius: 3px;
  background-color: #fff;
}

.selectPlan-tab-mobile-active>p {
  color: var(--primary);
}

.mobileTab-planDetails {
  position: relative;
  background-color: #fff;
}

.selected-policy-tab {
  position: absolute;
  top: -10px;
  left: 1rem;
  background-color: var(--primary);
  color: #fff;
  font-size: 10px;
  padding: 3px 5px;
  font-style: normal;
  font-weight: 500;
  border-radius: 3px;
}

.head-mmv-modal-planDetails>img {
  position: absolute;
  right: 1rem;
  top: 10px;
}

.head-mmv-modal-planDetails>img {
  width: 25%;
  aspect-ratio: 3 / 2;
  object-fit: contain;
}

.plandetails-tab-value>p:first-child {
  color: var(--text_color_three);
  font-weight: 500;
}

.plandetails-tab-value>p:last-child {
  color: var(--text_color_three);
  font-weight: 400;
}

.footer-plandetails-tab {
  display: none;
}

.hideInPC {
  display: none;
}

.covered-details-mobile {
  /* border: 1px solid blue; */
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 10px 0px;
}

.tab-img-title {
  /* border: 1px solid red; */
  width: 6rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
}

.tab-img-title>p {
  text-align: center;
  color: var(--text_color_one);
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.coveredDetails-plandetails-mob {
  padding: 0;
}

.od-container-mob {
  /* border: 1px solid blue; */
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.tp-container-mob {
  /* border: 1px solid green; */
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.totalPrice-container-mob {
  /* border: 1px solid green; */
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.prem-breakup-container-mob>p {
  color: var(--text_color_three);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 1rem;
}

.prem-breakup-container-mob {
  margin: -1rem;
  padding: 1rem;
  background: var(--gradient-twelve);
}

.switch-container-modal {
  display: flex;
  /* width: 50%;
  margin: auto; */
  justify-content: space-between;
  align-items: center;
}

.switchBtn-container>* {

  height: 2.8rem;
  width: 5rem;

  background-color: #fff;
  cursor: pointer;
}

#activeStwitchbtn {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-top: 1px solid #B1C9FF;
  border-bottom: 1px solid #B1C9FF;
  border-left: 1px solid #B1C9FF;
  border-right: none;
  transition: width 2s;
}

#noactiveStwitchbtn {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-top: 1px solid #B1C9FF;
  border-bottom: 1px solid #B1C9FF;
  border-right: 1px solid #B1C9FF;
  border-left: none;
  transition: width 2s;
}

.active-switchclass {
  background-color: var(--Primary-Blue, #5086FF);
  color: white;
  font-size: 12px;
}

.Non-active-switch-class {
  color: #5086FF;
  font-size: 12px;
}

.textnilldeppervious {
  color: #000;
  font-size: 12px;
}

.btnContainer-planDetails {
  /* border: 1px solid red; */
  background-color: #fff;
  position: sticky;
  padding-bottom: 1rem;
  bottom: 0;
}

.btn-regNo-container {
  /* border: 1px solid red; */
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
}

.acko_smart_saver_popup {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 2rem;
  gap: 1.8rem;
}

.smartZeodeptiltel {
  font-size: 1.5rem;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.acko_smart_saver_popup>div:nth-child(2) {
  font-size: 1rem;
}

/* RESPONSIVENESS --------------------------------------------------> */

@media only screen and (max-width: 610px) {



  .head-package-modal>div {
    align-items: flex-start;
    flex-direction: column;
  }

  .head-package-modal {
    justify-content: space-between;
  }

  .switchBtn-container>* {
    height: 2rem;
    width: 4rem;
  }

  .padding_breakup_container {
    border-radius: 10px 10px 10px 10px;
  }

  .exp-calendar-container {
    /* border: 1px solid red; */
    width: 100%;
  }

  .prem-breakup-container-mob {
    display: block;
  }

  .details-covered {
    margin-bottom: 10px;
  }

  .details-covered-bold>p {
    color: var(--text_color_three);
    font-weight: 700;
  }

  .details-covered-breakup-section-highlighted {
    border: none !important;
    background-color: #fff;
    padding: 0;
    margin-bottom: .5rem;
  }

  .details-covered-breakup-section-highlighted:last-child {
    /* border: 1px solid red !important; */
    padding: 5px 10px;
    margin-top: 1rem;
    margin-bottom: 0;
    border-radius: 3px;
    background-color: var(--background-color-twelve);
  }

  .rightSide_modal-plandetails {
    background-color: var(--background-color-eleven);
    padding: 1rem;
  }

  .hideInPC {
    display: block;
  }

  .hideInMobile {
    display: none;
  }

  .footer-plandetails-tab {
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    padding-top: 1.5rem;
    background: var(--gradient-eighteen);
    margin-top: -0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
    gap: 4rem;
  }

  .LogoofCom {
    justify-content: flex-start;
  }

  .package-container {
    width: 100%;
  }

  .zero-dep-tab-card {
    display: none;
  }

  .selectPlan-btn {
    display: none;
  }

  .bottomOptions_planCard_bg {
    background-color: #fff;
    border-top: 1px solid var(--background-colorThree);
  }

  .arrowRight-icon-mob {
    display: block;
    padding-bottom: 3px;
  }

  .premiumContainer_mobile-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }

  .padding_breakup_container_bg {
    background: linear-gradient(169deg, #DFE9FF -1.71%, rgba(255, 255, 255, 0.00) 107.73%);
  }

  .premiumContainer_mobile-container>img {
    width: 25%;
    aspect-ratio: 3 / 2;
    object-fit: contain;
  }

  .buyNowBtn_pc {
    display: none;
  }

  .buyNowBtn_pc-mobile {
    display: flex;
    width: auto;
    min-width: 7rem;
  }

  .idv_title_pc-mobile {
    font-size: 12px;
  }

  .idv_title_pc>span {
    font-size: 12px;
  }

  .block-mobile-plancard-header>img {
    width: 6rem;
  }

  .block-mobile-plancard-header {
    display: flex;
  }

  .cnfrmDetails-inputGrp-container {
    width: 100%;
  }

  .covered-details-container {
    width: 100%;
    height: auto;
  }

  .section-planDetails {
    height: 100%;
  }

  .btnContainer-planDetails {
    background-color: var(--background-colorTwo);
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    padding-bottom: 1rem !important;
    margin: 0rem -1rem;
    border-radius: 20px 20px 0px 0px;
    margin-bottom: -1rem;
  }

  .ins-card-planDetails {
    padding: 10px 10px;
    border: 1px solid var(--border_color_five);
  }

  .leftSide_modal-plandetails {
    background: #fff;
  }

  .breadCrumb-planDetails-active {
    box-shadow: none;
    background: transparent;
    border-bottom: 2px solid var(--primary) !important;
    color: var(--primary);
  }

  .breadCrumb-planDetails {
    border-radius: 0%;
    height: auto;
    width: fit-content;
    padding: 7px 10px;
    border-bottom: 2px solid var(--background-colorThree);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .breadCrumb-planDetails>p {
    width: max-content;
  }

  .breakup-head-planDetails {
    gap: 1rem;
  }

  .breadcrumb-container-plandetails {
    display: flex;
    overflow: auto;
  }

  .breadcrumb-container-plandetails>* {
    flex: 1 1;
  }

  .breadcrumb-container-plandetails::-webkit-scrollbar {
    display: none;
  }

  .coveredDetails-plandetails {
    /* padding: 1rem 1rem; */
    /* height: 78%; */
    margin-bottom: 0;
    border: none;
  }

  .hr-popUp {
    display: none;
  }

  .btnContainer_pform-cnfrmDetails-Mobile {
    display: flex !important;
  }

  .btnContainer_pform-cnfrmDetails-deskTop {
    display: none !important;
  }

  .head-confirmDetailContainer-popUp>p {
    margin-bottom: 1rem;
  }

  .polType-btn-popUp {
    font-size: 0.9rem;
  }

  .ins-card-planDetails {
    margin-bottom: 10px;
  }

  .leftSide_modal-plandetails {
    padding: 1rem;
  }

  .title-rightside-plandetails {
    display: none;
  }



  .polType-btn-popUp {
    font-size: .9rem;
  }

  .head-confirmDetailContainer-popUp>img {
    display: none;
  }

  .option_div_pc>p {
    font-size: 0.9rem !important;
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--primary) !important;
  }

  .form-btn-inputGrp {
    flex-wrap: wrap;
  }

  .form-btn-inputGrp-div {
    padding: 0px;
  }

  .confirm-details-parent {
    width: 100dvw;
    height: 100dvh;
  }

  .confirmDetailContainer-popUp {
    width: auto;
  }

  .form-cnfrmDetails-popup {
    gap: 1rem;
    padding: 0rem;
    border: none;
    height: fit-content;
    margin-bottom: 1rem;
    overflow: unset;
  }

  .plancard_card {
    min-width: none;
    max-width: none;
    width: 100%;
  }

  .premiumContainer_pc {
    padding: 10px 10px;
  }

  .modal-plandetails {
    width: 100dvw;
    justify-content: flex-start;
    /* zoom: .9 ; */
  }

  .plan-detail-parent {

    flex-direction: column;
  }

  .coveredDetails-plandetails {
    width: 100%;
    max-height: none;
  }

  .form-cnfrmDetails-inputGrp {
    padding: 0px;
    gap: 1rem;
    flex-direction: column;
  }

  .checkbox_container-popUp {
    padding: 10px 0px;
    padding-bottom: 0px;
  }
}

/* acko zero dep plan option popup */
.acko_zeroDep_planoption {
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 1rem;
}

.acko_zeroDep_planoption>div:nth-child(1) {
  background: var(--gradient-one);
}

.acko_zeroDep_planoption>div:nth-child(2) {
  border: 1px solid red;
}

.zerodep_safeDrive {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

}

.acko_SmartSaverContainer {
  /* margin-top: 1rem; */
  /* height: 600px; */


}

.smartZeroDepAcko {
  padding: 1.5rem;
  border-radius: 0px 0px 15px 15px;
  background: #FFF;
  /* border: 1px solid pink; */
  max-width: 30rem;
  margin: auto;
  /* min-height: 18rem; */
  box-shadow: 0px 0px 50px 0px rgba(80, 134, 255, 0.20);
}

.smartZeroDepAckoflex {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.acko_smart_saver_carPicContainer {
  border-radius: 10px 10px 0px 0px;
  background: linear-gradient(93deg, rgba(177, 201, 255, 0.30) -0.31%, rgba(185, 165, 188, 0.30) 98.64%);
  /* width: 389.249px; */

}

.ackosmartZerodep {
  display: flex;
  flex-direction: column;
  /* gap: 1.5rem; */
  align-items: center;
}

.SmartDepHeading {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: center;
  margin-bottom: -6px;
}

.SmartDepHeading>p {
  color: #011627;
  font-family: Poppins;
  font-size: 14.083px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.exclusiveOffer {
  border-radius: 27.384px;
  display: flex;
  width: 40%;
  align-items: center;
  object-fit: contain;
  padding: 1rem;
  justify-content: center;
  background: linear-gradient(92deg, #FFF0D3 0.68%, #F6D4FF 103.18%);
  /* width: 9rem; */

}

.exclusiveOffer>p {
  color: #000;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 500;
}

.zerodep_safeDrive>p {
  color: #606A6A;
  font-size: 0.6rem;
  font-style: normal;
  font-weight: 500;

}

.SmartDepContent {
  font-size: 14px;
  line-height: 2rem;
}

.SmartDepBtn {
  width: 100%;
  text-align: center;
  background-color: var(--primary);
  color: white;
}

.SmartDepBtn {
  padding: 10px 1rem;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
}

.safe_Drive>p:nth-child(1) {
  color: #5086FF;
  font-size: 1rem;
  font-weight: 650;
}



.acko_zero_dep_Price>span:nth-child(1) {
  font-size: 1rem;
  font-weight: 600;
  color: #23D32A;
}

.acko_zero_dep_Price>span:nth-child(2) {
  font-size: 1rem;
  font-weight: 300;
  margin-left: 15px;
  text-decoration: line-through;
  color: #FF464D;

}

.what_happens_acko {
  /* border: 1px solid red; */
  border-radius: 7.824px;
  background: #E3E4FF;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 0.6rem;
  gap: 0.4rem;
  border-radius: 10px;
}

.acko_dep_content {
  /* margin-top: 0.6rem; */
}

.acko_dep_content>p {
  color: #606A6A;
  font-family: Poppins;
  font-size: 0.7rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.what_happens_acko>div {
  font-size: 0.6rem;
  font-weight: 500;

}

.what_happens_acko>p {
  color: #606A6A;
  font-size: 0.7rem;
  width: 90%;
  font-style: normal;
  justify-items: center;
  font-weight: 500;
  line-height: 1rem;
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .package-container {
    max-height: 111dvh;
    height: auto;
  }
}

@media only screen and (max-width: 405px) {

  .package-container {
    max-height: 90dvh;
    height: auto;
  }

  .confirmDetailContainer-popUp {
    zoom: .9;
  }

  .plan-detail-parent {
    zoom: .9;
  }
}


@media only screen and (max-width: 412px) {
  .package-container {
    max-height: 90dvh;
    height: auto;
  }

}

@media only screen and (max-width: 344px) {
  .package-container {
    max-height: 90dvh;
    height: auto;
  }

}