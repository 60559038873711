.black-blue-header {
    font-size: 1.5rem !important;
    color: var(--text_color_three) !important;
    font-weight: 600 !important;
}

.black-blue-header>span {
    color: var(--primary) !important;
    font-weight: 700 !important;
    font-size: 1.5rem !important;
}

.page-container-hero {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page-container-hero>img {
    width: 30rem;
}

.privacy-policy-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.privacy-policy-content>p:nth-child(2) {
    color: var(--text_color_three);
    font-size: .9rem;
}

.privacy-policy-content>p:nth-child(3) {
    color: var(--text_color_one);
    font-size: .9rem;
}

.acknowledgment-block-container-claims>p:first-child {
    font-size: 1.5rem;
    color: var(--text_color_three);
    font-weight: 600;
}

.acknowledgment-block-container-claims {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.acknowledgment-block-container-claims>span {
    text-transform: uppercase;
    font-size: .9rem;
    font-weight: 400;
}

.head-bold-privacy {
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: var(--text_color_three) !important;
}

.privacy-policy-content>p {
    color: var(--text_color_three);
    font-weight: 400;
}

.contact-privPolicy {
    display: flex;
    gap: 10px;
}

.contact-privPolicy>p:first-child {
    color: #000;
    font-weight: 600;
    /* font-size: .9rem; */
}

.contact-privPolicy>p:last-child {
    color: #000;
    font-weight: 400;
    /* font-size: .9rem; */
}

.contact-privPolicy-hyperLink>p:last-child {
    color: var(--primary);
    font-weight: 400;
    /* font-size: .9rem; */
    text-decoration: underline;
}

/* ***************RESPONSIVENESS******************* */

@media only screen and (max-width: 610px) {
    .page-container-hero {
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;
    }

    .page-container {
        padding: 1rem;
    }

    .grievance-block-elements {
        overflow-wrap: break-word;
    }

    .page-container-hero>img{
        width: 100%;
    }
}