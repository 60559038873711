.claim-container-hero {
    position: relative;
    padding: 3rem 4rem;
}

.claim-container-hero-services {
    padding-top: 1rem;
}

.claim-container-hero>img {
    position: absolute;
}

.circle-1-claim {
    right: 0%;
    top: -66%;
    z-index: -1;
}

.circle-2-claim {
    z-index: -1;
    left: 0;
}

.circle-3-claim {
    bottom: 0;
    left: 12rem;
}

.claim-content-hero-container {
    /* border: 1px solid blue; */
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.claim-content-hero-container>img {
    width: 40rem;
}

.claim-content-hero>p:first-child {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text_color_three);
}

.span-blue {
    color: var(--primary);
}

.claim-content-hero {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.claim-content-hero>p:nth-child(2) {
    color: var(--text_color_one);
    font-size: 1rem;
}

.claim-content-hero>p:last-child {
    color: var(--text_color_three);
    font-size: .9rem;
    font-weight: 400;
}

.span-black {
    color: var(--text_color_three);
    font-weight: 600;
}

.service-container-claim {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.head-service-container-claim {
    font-size: 1.5rem;
    color: var(--text_color_three);
    font-weight: 400;
}

.head-service-container-claim>span {
    font-weight: 700;
}

.service-container-claim>p:nth-child(2) {
    color: var(--text_color_one);
    font-size: .85rem;
}

.services-square-container-claim {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
    justify-content: space-between;
}

.service-square {
    width: 25%;
    height: 13rem;
    /* border: 1px solid blue; */
    border-radius: 16px;
    border: 2px solid var(--background-colorEight);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 3rem;
}

.service-square>p {
    font-weight: 500;
}

.service-square-health {
    padding-top: 0rem;
    padding-bottom: 2rem;
}

.service-square-health>img {
    width: 100%;
}

.bgSquare {
    position: absolute;
    width: 100%;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.claim-container-hero-intiate {
    background: linear-gradient(100deg, #E2EBFF 0%, #EBF1FF 100%);
}

.flex-block-container {
    /* border: 1px solid blue; */
    margin-top: 1.5rem;
    display: flex;
    gap: 2rem;
}

.block-cashless-claim {
    /* border: 1px solid red; */
    width: 25%;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0px 0px 30.7px 0px rgba(80, 134, 255, 0.18);
    padding: 1rem 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.block-cashless-claim>img {
    width: 2.7rem;
    height: 2.7rem;
}

.block-header-cashless-claim {
    color: #000;
    font-size: .9rem;
    height: 45px;
}

.block-summary-cashless-claim {
    color: var(--text_color_one);
}

.claim-stepper-img-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.claim-stepper-img-container>img {
    height: 30rem;
}

.stepper-line {
    position: relative;
    /* border: 0.09375rem solid var(--text_color_one); */
    height: 19.2rem;
    border-style: solid;
    border: 1px dashed var(--text_color_one);
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.claim-stepper-div {
    width: 55%;
    /* border: 1px solid violet; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    scale: 1.1;
}

.claim-stepper-div-accordian {
    width: 55%;
    /* border: 1px solid violet; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.claim-accordian-img-container>img {
    /* border: 1px solid red; */
    width: 37%;
    justify-content: center;
}

.claim-accordian-img-container {
    margin-top: 1rem;
}

.stepper-content-circle {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary);
    /* ba */
}

.stepper-content-circle>img {
    width: 1.5rem;
}

.stepper-content-claim-container {
    position: absolute;
    /* border: 1px solid red; */
    display: flex;
    left: -1.2rem;
    gap: 1rem;
    top: 0;
    align-items: center;
}

.stepper-content-claim {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.stepper-content-claim>img {
    width: 3rem;
    height: 3rem;
}

.stepper-content-claim-text>p:first-child {
    color: var(--primary);
    font-size: 1rem;
    font-weight: 500;
}

.summ-claim-stepper {
    color: var(--text_color_three);
    font-weight: 500;
    font-size: .8rem;
}

.summ-claim-stepper-two {
    color: var(--text_color_one);
}

.summ-claim-stepper>span {
    font-weight: 700;
}

.stepper-content-claim-container-two {
    top: 5.5rem;
}

.stepper-content-claim-container-three {
    top: 11rem;
}

.stepper-content-claim-container-four {
    top: 16rem;
}

.stepper-content-circle-two {
    background-color: #fff;
    border: 2px solid var(--text_color_one);
}

.stepper-content-claim-text-two>p:first-child {
    color: var(--text_color_one);
}

.claim-stepper-container {
    width: 100%;
}

.stepper-content-claim-text {
    width: 25rem;
}

.claim-container-hero-noPadding {
    /* border: 1px solid red; */
    padding-bottom: 0rem;
}

/* RESPONSIVENESSS -------------------------------------------------------- */

@media only screen and (max-width: 610px) {
    .claim-container-hero>img {
        display: none;
    }

    .claim-stepper-img-container>img {
        display: none;
    }

    .claim-content-hero-container {
        flex-direction: column;
        gap: 1rem;
    }

    .claim-container-hero {
        padding: 1rem;
    }

    .claim-content-hero-container>img {
        width: 100%;
    }

    .services-square-container-claim {
        flex-direction: column;
        align-items: center;
    }

    .service-square {
        width: 80%;
        padding-top: 4.5rem;
    }

    .service-square-health {
        padding-bottom: 6rem;
    }

    .flex-block-container {
        flex-direction: column;
    }

    .block-cashless-claim {
        width: 100%;
    }

    .claim-stepper-div-accordian {
        width: 100%;
        /* border: 1px solid red; */
        padding: 0px 23px;
        border-radius: 15px;
        box-shadow: 0px 0px 20px 0px rgba(80, 134, 255, 0.20);
    }

    .claim-stepper-container-hero {
        height: 32rem;
    }

    .claim-stepper-div {
        width: 100%;
    }

    .stepper-content-claim>img {
        width: 2rem;
        height: 2rem;
    }

    .stepper-content-claim-text {
        width: 60vw;
    }

    .stepper-content-claim-container {
        gap: 10px;

    }

    .stepper-content-claim {
        gap: 10px;
    }

    .stepper-line {
        height: 20rem;
    }

}