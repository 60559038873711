.redirectContainer {
    /* border: 1px solid red; */
    height: 100dvh;
    background: var(--gradient-six);
    backdrop-filter: blur(2px);
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100%; */
}

.content-block-redirect {
    height: 29rem;
    width: 29rem;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: var(--boxShadow);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    gap: .5rem;
}

.msg-edirect-succes {
    color: var(--greenText);
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 1rem;
}

.msg-edirect {
    color: var(--text_color_one);
    font-size: .9rem;
    font-weight: 400;
    text-align: center;
    line-height: 1.5rem;
}

.btn-redirect {
    margin-top: 1rem;
    width: 203px;
    height: 52px;
}

.msg-edirect-failed {
    color: var(--redTxt);
}

@media only screen and (max-width: 610px) {

    .content-block-redirect {
        width: 100%;
    }

    .redirectContainer {
        padding: 1rem;
    }

}