.collapsible_container {
    /* border: 1px solid red; */
    padding: 1rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.absolute-hyperlink {
    position: absolute;
    right: 2px;
    border: none;
    outline: none;
    background-color: #fff;
    top: -1.2rem;
}

.collapsible_head_content {
    /* border: 1px solid blue; */
    border-radius: 10px;
    padding: .5rem 1rem;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    box-shadow: var(--boxShadowThree);
    background-color: #fff;
}

.collapsible_head {
    padding: .7rem 1rem;
    display: flex;
    justify-content: space-between;
}

.collapsible_head_withBorder {
    border-bottom: 1px solid var(--border_color_three);
    gap: 10px;

}

.arrow-collapsible-down {
    width: 1rem;
    transition: transform .2s ease;
    /* transform: rotateZ(-90deg); */
}

.arrow-collapsible-down.arrow-collapsible-up {
    transform: rotateZ(-180deg);
}

.collapsible_head_title {
    font-size: .9rem;
    font-weight: 500;
    color: var(--text_color_three);
}

.collapsible_content {
    /* border: 1px solid red; */
    padding: 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 0;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.collapsible_content.show_collapsible_content {
    padding: 2rem 1rem;
    height: auto;
}

.collapsible_content>* {
    flex: 1 1;
    /* border: 1px solid red; */
}

.collapsible_content_group {
    display: flex;
    justify-content: space-between;
}

.collapsible_content_group>* {
    flex: 1 1;
}

.collapsibleHead {
    font-size: .85rem;
    color: var(--text_color_one);
}

.collapsibleDesc {
    font-size: .85rem;
    color: var(--text_color_three);
}

.collapsibleTab {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.btnContainer_pform_collapsible {
    /* background-color: var(--background-colorSeven); */
    padding: 0.5rem 0rem;
}

.main-collapsible {
    position: relative;
}

/* .btnContainer_pform_collapsible-fixed-mobile {
    border: 1px solid red;
    width: 100%;
    position: fixed;
    height: 2rem;
    background-color: red;
} */

.disabled-style {
    opacity: 0.6;
    cursor: not-allowed;
}

/* hdfcterms style */

#hdfcTermspdf {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 25px;
    border-radius: 10px;
}

#hdfcTermspdf>div:first-child {
    display: flex;
    align-items: center;
    gap: 15px;
}

#hdfcTermspdf>div:first-child img {
    width: 35px;
    height: 35px;
}

#hdfcTermspdf h1 {
    font-size: 1.5rem;
    margin: 0;
    font-weight: 500;
    color: var(--Primary-Blue, #5086FF);
}

#hdfcTermspdf hr {
    border: none;
    border-top: 1px solid gray;
    margin: 7px 0;
}

#hdfcTermspdf>div:nth-child(3) p {
    text-align: justify;
}

.secondry_btn_hdfc {
    border: 1px solid var(--primary);
    cursor: pointer;
    color: var(--primary);
    border-radius: 5px;
    background-color: #F3F7FF;
    width: 100%;
    max-width: 300px;
}

.secondry_btn_hdfc:hover {
    background-color: #DFE9FF;
}

.btn_pForm_hdfc {
    width: 100%;
    max-width: 15rem;
    height: 3.2rem;
}

.button.btn_pForm:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Media Queries */
@media (max-width: 768px) {
    #hdfcTermspdf {
        padding: 20px;
        gap: 0.8rem;
    }

    #hdfcTermspdf h1 {
        font-size: 1.25rem;
    }

    #hdfcTermspdf>div:first-child img {
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px) {
    #hdfcTermspdf {
        padding: 20px;
        gap: 0.5rem;
    }

    #hdfcTermspdf h1 {
        font-size: 1.25rem;
    }

    .secondry_btn_hdfc,
    .btn_pForm_hdfc {
        width: 100%;
    }
}


@media only screen and (max-width: 610px) {

    .adaptive-breakup-proposal {
        display: flex;
        flex-direction: column;
        gap: 10px;
        /* border: 1px solid red; */
        overflow: hidden;
        height: 0;
        transition: height .5s cubic-bezier(0, 1, 0, 1);
    }

    .adaptive-breakup-proposal.active-adaptive-breakup-proposal {
        height: 100%;
        overflow: auto;
    }

    .collapsible_head {
        padding: 0.7rem 0rem;
    }

    .collapsible_content.show_collapsible_content {
        padding: 1rem 0rem;
    }

    .absolute-hyperlink {
        font-size: .8rem !important;
    }

    .collapsibleDesc {
        font-weight: 500;
    }

    .btnContainer_pform_collapsible {
        /* display: none; */
        background-color: var(--background-colorSeven);
        padding: 1rem 1rem;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .collapsible_container {
        gap: 1rem;
    }

    .collapsible_content_group {
        flex-direction: column;
        gap: 1rem;
    }

    .collapsibleTab {
        flex-direction: column;
    }

    .collapsible_content {
        gap: 1rem;
    }
}