.toggle-container-settingsCard {
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    gap: 1rem;
}

.toggleLabel {
    color: var(--text_color_one);
}

.settings-container {
    /* border: 1px solid red; */
    padding: 1.3rem 1.4rem;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    box-shadow: var(--boxShadowFive);
}


.settings-container-tab {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--border_color_three);
}

.settings-container-tab:last-child {
    border-bottom: none;
}

.toggleLabel-head {
    font-size: .95rem;
    font-weight: 500;
}

.settings-name-content {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

/* import React, { useState } from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = (props) => {

     const { name, event, value, status, checked } = props;

    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };

    return (
        <div>
            <label className="switch-container">
                <input
                    type="checkbox"
                    checked={checked}
                    className="toggle-checkbox"
                    name={name}
                    onChange={(e) => event(e)}
                />
                <span className="slider-toggle round">
                    <p className={status == true ? "toggle-status" : "toggle-status-off"}>
                        {status === true ? "On" : "Off"}
                    </p>
                </span>
            </label>
        </div>
    );
};

export default ToggleSwitch; */