
.container404page {
    background-image:url('../../Assets/Images/background404.svg') ;
    margin: auto;
    background: var(--gradient-fifteen);
}
.boxcontaier404{
  
    width: 70%;
    margin: auto;
    text-align: center;
    padding: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.opps{
   color: #60646A;
   font-size: 2.5rem;
}
.content404{
    
    width: 40%;
    margin: auto;
    text-align: center;

}
.content404>h3{
    font-size: 1.5rem;
    font-weight: 570;

   
}
.content404>div>p{
    color: #60646A;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1rem 0px 1rem 0px;
}

.btn-404{
    width: 11rem;
    height: 3.2rem;

}
@media only screen and (min-device-width: 120px) and (max-device-width: 680px) {
    .boxcontaier404 {
      width: 100%;
    }
    .content404{
        width: 80%;   
    
    }
    .boxcontaier404>img{
        max-height:100%; 
        max-width:100%
    }
  }