.loader-container {
    border: 1px solid red;
    height: 88vh;
    display: flex;
    /* background: var(--gradient-one); */
    justify-content: center;
    align-items: center;
}

.loader-container>img {
    mix-blend-mode: multiply;
}

.loader-bmi {
    height: 200px;
    width: 200px;
}

.mobile_loader_container {
    display: none;

}

/* HTML: <div class="loader"></div> */
/* .loader {
    width: 60px;
    aspect-ratio: 4;
    --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #ffffff00);
    background:
        var(--_g) 0% 50%,
        var(--_g) 50% 50%,
        var(--_g) 100% 50%;
    background-size: calc(100%/3) 100%;
    animation: l7 1s infinite linear;
}

@keyframes l7 {
    33% {
        background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
    }

    50% {
        background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
    }

    66% {
        background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
    }
} */

.loader {
    width: 40px;
    height: 40px;
    --c: no-repeat linear-gradient(var( --primary) 0 0);
    background: var(--c), var(--c), var(--c), var(--c);
    background-size: 21px 21px;
    animation: l5 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes l5 {
    0% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%
    }

    33% {
        background-position: 0 0, 100% 0, 100% 100%, 0 100%;
        width: 60px;
        height: 60px
    }

    66% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0;
        width: 60px;
        height: 60px
    }

    100% {
        background-position: 100% 0, 100% 100%, 0 100%, 0 0
    }
}

@media only screen and (max-width: 610px) {
    .desktopLoader_container {
        display: none;
    }

    .mobile_loader_container {
        display: flex;
        position: fixed;
        inset: 0;
        /* background-color: rgba(44, 45, 45, 0.526); */
        background: var(--gradient-four);
        /* z-index: 9999; */
        backdrop-filter: blur(2px) !important;
        z-index: 9;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
}