.block-container-grievance>p:first-child {
    color: var(--text_color_three);
    font-size: 1.5rem;
    font-weight: 600;
}

.block-container-grievance>p:nth-child(2) {
    color: var(--text_color_three);

}

.block-container-grievance {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.block-container-grievance>span {
    color: var(--text_color_one);

}

.grievance-content>p {
    color: var(--text_color_one);
}

.grievance-content>p>span {
    font-weight: 700;
}

.grievance-block {
    border: 1px solid var(--border_color_two);
    border-radius: 10px;
    margin-bottom: 1rem;
}

.grievance-block-header {
    border-radius: 10px 10px 0px 0px;
    background: rgba(80, 134, 255, 0.25);
    padding: 1rem;
}

.grievance-block-elements {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.grievance-block-elements-gray {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.grievance-block-elements-gray>p {
    color: var(--text_color_one);
    font-weight: 400;
}

.grievance-block-elements>p {
    color: #000;
    font-weight: 500;
}

.grievance-block-elements>p>span {
    color: var(--text_color_one);
    font-weight: 400;
}

.grievance-block-header>p {
    color: var(--text_color_three);
    font-weight: 600;
}

.grievance-block-elements- {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem;
}

.grievance-block-elements->span{
    color: var(--text_color_one);
}