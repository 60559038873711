@media only screen and (max-width: 610px) {
    .cards_container {
        padding: 2rem 1rem;
        display: none;
    }

    .card_container_child {
        justify-content: space-around;
        gap: 1rem;
    }

    .card_div_container {
        gap: 1rem;
    }

    .card_landing {
        width: 45%;
        margin-bottom: 10px;
        min-height: 14rem;
        min-width: 0;
        padding: 1rem;
        border-radius: 20px;
    }
}

@media only screen and (min-width: 610px) and (max-width: 1111px) {
    .card_container_child {
        justify-content: flex-start;
    }
}

/* ==========Css On Hover================ */
.slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


@media only screen and (min-width: 120px) and (max-width: 609px) {
    .spacingClassformolie {
        padding-top: 0.5rem;
    }

    .card_landing {
        min-height: 14rem;
        font-size: small;

    }
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-12px);
        transform: translateY(-12px);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}