.page-container {
    padding: 3rem 4rem;
}

.page-container-contactUs {
    background-image: url("../../../Assets/Images/Background.jpg");
    background-repeat: no-repeat;
    background-size: 100% 300px;
    /* border: 1px solid red; */
}

.page-container-parent-contactUs {
    background-color: #fff;
    display: flex;
    box-shadow: var(--boxShadowSix);
    border-radius: 15px;
}

.map-container {
    width: 50%;
    border-radius: 15px 0px 0px 15px;
    padding: 2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    /* background: linear-gradient(169deg, rgba(255, 255, 255, 0.00) -1.71%, #DFE9FF 107.73%); */
}

.map-container>img {
    /* border: 1px solid red; */
    width: 85%;
    margin: auto;
}

.mobile-contact-us-content-pc {
    width: 50% !important;
    padding: 2rem !important;
    justify-content: center;
    gap: 2rem !important;
    border-radius: 15px !important;
}

.mobile-contact-us-content-pc>p {
    font-size: 1rem !important;
}

.form-container-contactus {
    padding: 2rem;
    padding-right: 10rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 50%;
    border-radius: 15px;
    box-shadow: var(--boxShadowSeven);
}

.map-container>p {
    font-size: 1.5rem;
    font-weight: 700;
}

.map-container>p>span {
    color: var(--primary);
}

.btn-container-contactus {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.btn-container-contactus .btn_contactUs {
    width: 50%;
    height: 3.2rem;
}

.form-container-contactus>p {
    color: var(--text_color_three);
    font-weight: 500;
    font-size: .85rem;
}

.quick-contacts-container {
    /* border: 1px solid red; */
    margin-top: 2rem;
}

.quick-contacts-container>p {
    color: #000;
    font-size: 1.5rem;
    font-weight: 500;
}

.quick-contacts-container>p>span {
    font-weight: 700;
}

.block-contact-us {
    display: flex;
    /* border: 1px solid red; */
    align-items: flex-start;
    gap: .5rem;
}

.block-contact-us>img {
    width: 1.2rem;
}

.communication-block-contact-us>p:first-child {
    color: var(--primary);
    font-weight: 700;
}

.communication-block-contact-us>p:last-child {
    font-weight: 600;
    margin-top: .5rem;
}

.social-icons-contact {
    display: flex;
    gap: 1.5rem;
}

.social-icons-contact>img {
    width: 2rem;
}

.planeIcon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.block-contact-us:nth-child(3) {
    align-items: center;
}

.block-contact-us:nth-child(4) {
    align-items: center;
}

.mobile-contact-us-screen {
    display: none;
}

.mobile-contact-us-content {
    /* border: 1px solid blue; */
    width: 100%;
    border-radius: 30px 30px 0px 0px;
    background: #FFF;
    box-shadow: 0px -1px 27.2px 0px rgba(80, 134, 255, 0.23);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.mobile-contact-us-content>p {
    color: var(--text_color_three);
    font-weight: 500;
    font-size: .85rem;
}

.mobile-contact-us-content>p>span {
    font-weight: 700;
}

.mobile-contact-block {
    border: 1px solid var(--background-colorThree);
    border-radius: 5px;
    background: #FFF;
    box-shadow: 0px 4px 12px 0px rgba(80, 134, 255, 0.11);
    padding: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
}

.icon-cont-mob {
    /* border: 1px solid blue; */
    height: 3.5rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    align-items: center;
    background: #EAE8FD;
}

.icon-cont-mob>img {
    width: 2rem;
    height: 2rem;
}

.content-cont-mob>p {
    font-size: .73rem;
}

.content-cont-pc>p {
    font-size: .83rem;
}

.icon-cont-pc {
    height: 4rem;
    width: 4rem;
}

.content-cont-mob {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.mobile-contact-us-content {
    font-size: .73rem;
}

.content-cont-mob>p:first-child {
    color: var(--text_color_one);
    font-weight: 500;
}

.content-cont-mob>p:last-child {
    color: var(--text_color_three);
    font-weight: 500;
}

.content-cont-mob>p:nth-child(2) {
    color: var(--text_color_three);
    font-weight: 500;
}

.block-contact-us-center {
    align-items: center;
}

.communication-block-contact-us-two>p>a {
    font-weight: 600;
}

/* background: linear-gradient(169deg, rgba(232, 228, 245, 0.00) -1.71%, #DFE9FF 56.73%); */

/* *********************RESPONSIVENES********************** */

@media only screen and (max-width: 610px) {

    .page-container-contactUs {
        background: linear-gradient(169deg, rgba(232, 228, 245, 0.00) -1.71%, #DFE9FF 56.73%);
    }

    .mobile-contact-us-screen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        /* border: 1px solid red; */
        margin: 0rem -1rem;
    }

    .mobile-contact-us-screen>img {
        width: 55%;
    }

    .page-container-parent-contactUs {
        flex-direction: column;
        display: none;
    }

    .map-container {
        width: 100%;
        border-radius: 0px;
        height: 23rem;
    }

    .form-container-contactus {
        width: 100%;
        padding-right: 2rem;
    }

    .planeIcon {
        width: 29%;
    }

}