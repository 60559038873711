.nav_container {
    height: 70px;
    display: flex;
    align-items: center;
    background-color: #fff;
    /* position: sticky; */
    top: 0;
    /* z-index: 1; */
    padding: 0rem 4rem;
    box-shadow: var(--boxShadowFour);
}

.nav_parent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.navLogo {
    width: 10rem;
    cursor: pointer;
}

.nav_child {
    display: flex;
    align-items: center;
    margin-top: -8px;
}

.nav_menu {
    gap: 1rem;
}

.navItem_text {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.navItem {
    color: var(--text_color_one);
    font-size: .86rem;
    display: flex;
    gap: .4rem;
    cursor: pointer;
    /* border: 1px solid red; */
}

.navItem_agent {
    color: var(--text_color_one);
    font-size: .86rem;
    /* display: flex; */
    gap: .4rem;
    cursor: pointer;
    /* border: 1px solid red; */
}

.nav_btn {
    width: 89px;
    height: 38px;
}

.nav_mob_menu_toggle {
    display: none;
}

.nav_menuMob {
    width: 0%;
}

.my-account-btn {
    /* border: 1px solid red; */
    display: flex;
    min-width: 120px;
    height: 38px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 5px;
    gap: .5rem;
    background-color: var(--primary);
    color: #fff;
}

.icon-circle-navbar {
    /* border: 1px solid blue; */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    align-items: center;
}

.navMobLeft_section {
    display: flex;
    align-items: center;
    gap: 1rem;
}

/* ****************************RESPONSIVENESS************************* */

@media only screen and (max-width: 1126px) {
    .nav_menuPc {
        display: none;
    }

    .navItem_text {
        /* border: 1px solid red; */
        width: 100%;
        align-items: flex-start;
        padding: 0rem 0rem;
        flex-direction: column;
        gap: 0rem;
    }

    .navItem {
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid var(--background-colorThree);
        padding: 1rem;
        color: var(--text_color_three);
        font-size: .9rem;
    }

    .nav_menuMob {
        display: flex;
        position: fixed;
        box-shadow: var(--boxShadow);
        /* border: 1px solid red; */
        flex-direction: column;
        align-items: center;
        /* gap: 1rem; */
        z-index: 2;
        background-color: #fff;
        width: 50%;
        top: 0;
        bottom: 0;
        right: 0;
    }

    .nav_menuMob_head {
        height: 70px;
        width: 100%;
        display: flex;
        padding: 5px 1rem;
        align-items: center;
        /* background-color: var(--background-colorTwo); */
        justify-content: space-between;
        border-bottom: 3px solid var(--background-colorThree);
    }

    .nav__cross {
        color: var(--primary);
    }

    .nav_mob_menu_toggle {
        cursor: pointer;
        display: block;
    }

    .featureParts_container_child {
        gap: 1.5rem;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 595px) {


    .nav_menuMob {
        /* right: -1rem; */
        width: 100%;
    }
}

@media only screen and (max-width: 390px) {
    .nav_container {
        height: 55px;
        /* box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.16); */
    }

    .nav_menuMob_head {
        height: 55px;
    }
}

@media only screen and (max-width: 610px) {
    .nav_container {
        padding: 0rem 1rem;
        /* box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.16); */
        /* z-index: 1; */
    }
}