.hero-section-product {
    padding: 3rem 4rem;
    background: var(--gradient-twelve);
    /* border: 1px solid red; */
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.product-ins-hero-img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 33rem;
    object-fit: cover;
}

.hero-content-product-ins {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* border: 1px solid green; */
}

.title-product-ins {
    font-weight: 400;
}

.title-product-ins-margin {
    margin-bottom: 2rem;
}

.title-product-ins>span {
    font-weight: 700;
}

.Owner-ship-txt>p {
    padding-top: 2rem;
    font-size: 20px;
    color: #60646A;
}

.guid-line-usedcar {
    display: flex;
    align-items: center;
}

.guid-line-usedcar>img {
    width: 20px;
    height: 30px;
    margin-right: 12px;
}

.guid-line-usedcar>p {
    margin: 0;
    color: #333;
    /* Darker text color */
    font-size: 14px;
    /* Consistent font size */
    line-height: 1.5;
}

.hero-content-product-ins>p {
    font-size: 0.9rem;
    color: var(--text_color_one);
}

.content-container-prod-insurance {
    /* border: 1px solid red; */
    padding: 2rem 4rem;
}

.list-container-prod-ins {
    /* border: 1px solid orange; */
    display: flex;
    justify-content: space-between;
    gap: 4rem;
}

.list-container-prod-ins>* {
    flex: 1 1;
}

.list-rectangle {
    /* border: 1px solid blue; */
    display: flex;
    gap: 1rem;
}

.list-rectangle-icon-square {
    /* border: 1px solid green; */
    width: 5rem;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-shadow: var(--gradient-thirteen);
}

.list-rectangle-icon-square>img {
    width: 2.3rem;
}

.list-rectangle-content {
    display: flex;
    flex-direction: column;
    /* gap: 3px; */
    /* border: 1px solid red; */
    width: 81%;
}

.list-rectangle-content>p:first-child {
    color: var(--text_color_three);
    font-size: 1rem;
    font-weight: 600;
}

.list-rectangle-content>p:last-child {
    color: var(--text_color_one);
    font-size: 0.9rem;
}

.list-container-prod-block {
    /* border: 1px solid blue; */
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.cards-container-product-ins {
    /* border: 1px solid blue; */
    margin-top: 2rem;
    /* display: ; */
    display: flex;
    flex-direction: row;
    gap: 3rem;
    justify-content: flex-start;
    /* flex-wrap: wrap; */
}

.card-prod-ins {
    /* border: 1px solid red; */
    border: 1px solid var(--background-colorThree);
    width: 25%;
    height: 25rem;
    border-radius: 15px;
    min-width: 251px;
    padding: 1rem;
    background: var(--gradient-fourteen);
    position: relative;
}

.ins-logo-container {
    height: 4rem;
    width: 100%;
    border-radius: 5px;
    /* border: 1px solid green; */
    display: flex;
    background-color: #fff;
    padding: 0rem 1rem;
    overflow: hidden;
    justify-content: flex-start;
    align-items: center;
}

.ins-logo-container>img {
    /* height: 100%; */
    /* width: 100%; */
    /* border: 1px solid blue; */
    object-fit: contain;
    height: 3rem;
    width: 5rem;
    aspect-ratio: 3 / 1;
}

.breakup_container-dummy {
    margin-top: 1rem;
}

.bottom-card-btn {
    background-color: var(--primary);
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    margin: 0rem -1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.comparison-container-prod-insurance {
    background: var(--gradient-twelve);
    padding-bottom: 4rem;
}

.policy-container-prod-pg {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    padding: 3rem 0rem;
    padding-bottom: 4rem;
}

.policy-container-prod-pg>* {
    flex: 1 1;
    /* border: 1px solid blue; */
}

.policy-container-block {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.icon-container-policy-container-block {
    /* border: 1px solid red; */
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    box-shadow: var(--boxShadow);
    justify-content: center;
    align-items: center;
}

.policy-container-block-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.policy-container-block-head {
    color: #000;
    font-size: 1.2rem;
    font-weight: 500;
}

.policy-container-block-desc {
    color: var(--text_color_one);
}

.table-container-prod-pg {
    /* border: 1px solid red; */
    padding: 1.5rem 2.5rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(80, 134, 255, 0.2);
}
.table-container-prod-pg-usedcar{
    /* border: 1px solid red; */
    padding: 1.5rem 2.5rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 0px 20px 0px rgba(80, 134, 255, 0.2);
    margin-top: 4rem;
}
.table-container-prod-pg-usedcar>div{
    line-height: 1rem;
   padding: 1rem;
}

.custom-table-head {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid var(--border_color_three);
    margin-bottom: 1rem;
}

.custom-table-head>* {
    flex: 1 1;
    text-align: center;
    font-size: 1rem;
}

.custom-table-head>p:first-child {
    text-align: left;
    padding-left: 1rem;
}

.custom-table-body {
    background-color: var(--background-colorFive);
    padding: 1rem 1rem;
    display: flex;
}

.custom-table-body>* {
    flex: 1 1;
}

.row-container> :first-child {
    text-align: left;
    width: 33%;
    word-wrap: break-word;
}

.row-container> :last-child {
    border-right: 0;
}

.row-container {
    display: flex;
}

.row-container>* {
    flex: 1 1;
    border-right: 1px solid var(--border_color_two);
    border-bottom: 1px solid var(--border_color_two);
    text-align: center;
}

.p-tag-custom-table {
    width: 33.5%;
    padding: 8px 0px;
}

.p-tag-custom-table>img {
    width: 1rem;
}

.explanation-container-prod-insurance {
    background: var(--background-color);
    padding: 9rem 4rem;
    padding-bottom: 4rem;
    /* padding-top: 10rem; */
}

.prem-calc-container-prod-insurance {
    background: #fff;
    padding: 2rem 4rem;
    padding-bottom: 2rem;
}

.mobile-container-with-content {
    display: flex;
    padding: 1rem 0rem;
    gap: 1rem;
}

.mobile-container-with-content-two {
    /* border: 1px solid red; */
    min-height: 34rem;
    justify-content: space-between;
}

.mob-container-prod-pg {
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-container-prod-pg {
    width: 65%;
}

.content-container-prod-pg-two {
    /* border: 1px solid blue; */
    width: 50%;
}

.w2b-content-product-ins {
    width: 100%;
}

.circle-gradient-prod-pg {
    height: 17rem;
    width: 17rem;
    position: relative;
    border-radius: 50%;
    background: var(--gradient-twelve);
}

.calc-gradient-prod-pg {
    height: 17rem;
    width: 17rem;
    position: relative;
    border-radius: 50%;
    background: var(--gradient-twelve);
}

.calc-gradient-prod-pg>img {
    width: 100%;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}

.circle-gradient-prod-pg>img {
    width: 80%;
    position: absolute;
    bottom: 1rem;
    right: -2rem;
}

.card_div_container-prod-pg {
    margin-top: 3rem;
}

.timeline-container-and-lady {
    display: flex;
    /* border: 1px solid red; */
    margin-top: 2rem;
    gap: 1rem;
    align-items: center;
}

.lady-container-prod-pg {
    width: 40%;
}

.timeline-container-prod-pg {
    width: 60%;
    /* height: 10rem; */
}

.lady-box-prod-pg {
    width: 65%;
    max-width: 270px;
    height: 24rem;
    /* margin: auto; */
    border-radius: 25px;
    background-color: #fff;
    /* border: 1px solid ; */
    position: relative;
    box-shadow: var(--boxShadow);
}

.lady-box-prod-pg>img {
    width: 150%;
    position: absolute;
    bottom: 0;
    /* left: 0; */
}

.bold-description-calc {
    color: var(--text_color_three) !important;
    font-weight: 500;
    font-size: 0.9rem !important;
}

.checklist-calc-container {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
}

.checklist-calc-container>img {
    width: 1rem;
}

.checklist-calc-container>p {
    color: var(--text_color_one);
}

.checklist-calc-container-parent {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.formula-container-prod-pg {
    border: 1px solid blue;
    /* height: 10rem; */
    border-radius: 10px;
    padding: 4rem;
    background: var(--border_color_three);
}

.formulas-calculations-one {
    margin: 1rem 0rem;
}

.formulas-calculations {
    color: #000;
    font-size: 0.85rem;
}

.noPadding {
    padding: 0rem !important;
}

.partner-container-prod-pg {
    padding: 2rem 0rem;
}

.covered-rect-comp-ins {
    /* border: 1px solid red; */
    padding: 2rem 2rem;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
}

.covered-container-compinspolicy {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.success-icon>img {
    width: 1rem;
}

.covered-point-comp-ins {
    display: flex;
    gap: 1rem;
}

.point-title-comp-ins {
    font-weight: 700;
}

.point-title-comp-ins>span {
    font-weight: 400;
}

.p-tag-container-compins {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.points-comp-ins {
    display: flex;
    gap: 1rem;
}

.covered-container-prod-insurance-parent {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.hero-section-product-tp>img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    width: 40rem;
}

.hero-section-product-tp-useCar>img {
    /* -webkit-transform: scaleX(-1); */
    /* transform: scaleX(-1); */
    width: 32rem;
}

.table-container-prod-pg-mobile {
    display: none;
    /* border: 1px solid red; */
}

.table-container-prod-pg-mobile-parent {
    display: flex;
    flex-direction: column;

}

.head-title-tab-mob {
    display: flex;
    flex-direction: column;
    /* border: 1px solid blue; */
    width: 100%;
}

.heading-tab-mobile {
    padding: 10px 10px;
    margin: 0px -10px;
    background-color: var(--background-colorFive);
}

.heading-tab-mobile>p {
    font-weight: 600;
    color: var(--text_color_three);
}

.value-container-tab-mobile-parts>p {
    color: var(--text_color_one);
    font-weight: 400;
}

.value-container-tab-mobile {
    border: 1px solid var(--border_color_three);
    display: flex;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    /* gap: 5px; */
}

.value-container-tab-mobile>* {
    flex: 1 1;
    padding: 10px 0px;
}

.value-container-tab-mobile-parts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.value-container-tab-mobile-parts-one {
    border-right: 1px solid var(--border_color_two);
}

@media only screen and (max-width: 610px) {

    .covered-rect-comp-ins {
        padding: 1rem 1rem;
    }

    .card-prod-ins {
        height: 26rem;
    }

    .icon-container-policy-container-block {
        height: 4rem;
        width: 4rem;
    }

    .product-ins-hero-img {
        width: 90%;
    }

    .hero-section-product-tp-useCar>img {
        width: 20rem;
    }

    .product-ins-hero-img-tp {
        width: 129% !important;
    }

    .hero-content-product-ins {
        width: 100%;
    }

    .cards-container-product-ins {
        /* border: 1px solid red; */
        /* flex-wrap: wrap; */
        overflow: scroll;
        margin-right: -1rem;
        padding-bottom: 2rem;
    }

    .explanation-container-prod-insurance {
        padding: 1rem 1rem;
    }

    .mob-container-prod-pg {
        display: none;
    }

    .content-container-prod-pg {
        width: 100%;
    }

    .content-container-prod-insurance {
        padding: 1rem 1rem;
        padding-bottom: 2rem;
    }

    .policy-container-block {
        align-items: flex-start;
    }

    .policy-container-prod-pg {
        flex-direction: column;
        gap: 2rem;
        padding: 1rem 0rem;
        padding-bottom: 2rem;
    }

    .list-container-prod-ins {
        flex-direction: column;
        gap: 2rem;
    }

    .list-container-prod-block {
        padding: 0;
    }

    .lady-container-prod-pg {
        display: none;
    }

    .hero-section-product {
        padding: 1rem 1rem;
        display: block;
        height: 25rem;
    }

    .hero-section-product-tp {
        height: 30rem;
    }

    .-marginBottom {
        margin-bottom: 2rem;
    }

    .timeline-item {
        height: 100% !important;
        padding: 0px !important;
        margin: 25px 0px;
        padding-left: 15px !important;
    }

    .timeline-container-prod-pg {
        width: 100%;
    }

    .timeline-content {
        margin-left: 27px !important;
        border-radius: 5px !important;
        padding: 1rem !important;
    }

    .vertical-timeline {
        padding-left: 1rem !important;
    }

    .formula-container-prod-pg {
        padding: 1rem;
    }

    .timeline-content:after {
        top: 43%;
    }

    .title-product-ins {
        font-size: 1.6rem;
    }

    .table-container-prod-pg {
        display: none;
    }

    .table-container-prod-pg-mobile {
        display: block;
        padding: 10px;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: var(--boxShadow);
    }

}