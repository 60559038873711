.radio-group {
    display: flex;
    flex-direction: column;
}

.radio-label {
    display: flex;
    align-items: center;
    padding: 0.4rem 0.5em;
    background-color: #fff;
    border: 1px solid var(--border_color_two);
    min-width: 6rem;
    font-size: .85rem;
    color: var(--text_color_one);
    cursor: pointer;
    height: 38px;
    border-radius: 5px;
    transition: background-color 0.2s, border-color 0.2s;
}

.radio-input {
    position: absolute;
    opacity: 0;
    display: none;
}

.radio-input:checked+.radio-label {
    background-color: #fff;

}

.radio-input:focus+.radio-label {
    outline: 2px solid blue;
}

.radio-inner-circle {
    display: inline-block;
    width: 22px;
    height: 22px;
    border: 1px solid #DFE9FF;
    border-radius: 50%;
    margin-right: 0.5em;
    transition: border-color 0.2s;
    position: relative;
    background-color: var(--background-color);
}

.radio-label:hover .radio-inner-circle {
    border-color: #DFE9FF;
}

.radio-input:checked+.radio-label .radio-inner-circle {
    border-color: #DFE9FF;
}

.radio-input:checked+.radio-label .radio-inner-circle::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid #B1C9FF;
    background-color: #5086FF;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.radio-inactive-circle {
    width: 10px;
    height: 10px;
    border: 1px solid #DFE9FF;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.radio-input:checked+.radio-label .radio-inner-circle {
    border: 1px solid #B1C9FF;
}

/* RESPONSIVENESS --------------------------------------------------> */

@media only screen and (max-width: 610px) {
    .radio-label {
        padding: 0.3rem 0.5em;
    }
}