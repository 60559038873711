.css-153n0d3-MuiSkeleton-root {
    border-radius: 3px;
    background-color: #d6e0f9 !important;
}

.css-5h11db {
    border-radius: 3px;
    background-color: #d6e0f9 !important;
}

.css-1tgsk0c {
    border-radius: 3px;
    background-color: #d6e0f9 !important;
}