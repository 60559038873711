.getRegNo_container {
    /* border: 1px solid red; */
    background: var(--gradient-one);
    padding: 3rem 4rem;
    padding-top: 4.5rem;
    position: relative;
}

.getRegNo_form {
    /* border: 1px solid blue; */
    position: relative;
    padding: 1.4rem 1.4rem;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    box-shadow: var(--boxShadow);
    /* border: 1px solid blue; */
}

.form-vehicleNumber-home-main {
    /* border: 1px solid red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 1rem;

}

.product_selction_getregno {
    /* border: 1px solid red; */
    position: absolute;
    top: -2.2rem;
    display: flex;
    gap: 1rem;
}

.prod_btn {
    /* border: 1px solid blue; */
    border-radius: 10px 10px 0px 0px;
    min-width: 10.5rem;
    display: flex;
    cursor: pointer;
    outline: none;
    border: none;
    background-color: var(--background-color);
    justify-content: center;
    height: 36px;
    align-items: center;
    padding: 4px 0px;
}

.active_prod_btn {
    background-color: #fff;
}

.prod_btn>img {
    margin-right: 10px;
    width: 32px;
    height: 19.487px;
}

.prod_btn>span {
    color: var(--primary);
    margin-right: 4px;
}

.getRegNo_form_part_one {
    width: 60%;
    /* border: 1px solid red; */
}

.getRegNo_form_part_two {
    width: 40%;
}

.getRegNo_form_part {
    padding: .5rem;
}

.width {
    width: 100%;
}

.width-mobile {
    width: 100%;
}

.irdai_container {
    /* border: 1px solid; */
    padding: 35px 35px;
    background-color: var(--background-color);
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.irdai_container_head {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    /* border: 1px solid red; */
}

.irdai_container_head>img {
    width: 5rem;
    height: 2rem;
}

.irdai_small_text {
    color: #000;
}

.getRegNo_highlight_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.getRegNo_highlight_container_part {
    /* border: 1px solid rgb(101, 101, 2); */
    display: flex;
    justify-content: space-between;
}

.numbers_getregno {
    font-size: 2.5rem;
    font-weight: 600;
}

.numbers_getregno_blue {
    color: var(--primary);
}

.getRegNo_form_head {
    color: black;
    font-weight: 400;
    font-size: 1.5rem;
}

.getRegNo_form_head>span {
    font-weight: bold;
}

.getRegNo_form_inp_parts {
    display: flex;
    justify-content: space-between;
    gap: 1.7rem;
    align-items: flex-start;
}

.input_container {
    width: 72%;
}

.input_container_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    gap: 1rem;
    flex-wrap: wrap;
}

.input_container_btn>* {
    flex: 1 1 172px;
}

.input_container_content {
    width: 40%;
    /* border: 1px solid red; */
}

.getRegNo_form_inp_container {
    /* border: 1px solid red; */
    padding: 1rem 0rem;
    display: flex;
    padding-top: 0;
    flex-direction: column;
    gap: 2rem;
}

.GetRegNo_btn {
    height: 52px;
    width: 100%;
    min-width: 10.7rem;
    padding: 0rem 1rem;
}

.small_text_flex {
    display: flex;
    gap: .3rem;
}

.bottom_getRegno_ {
    display: flex;
    gap: 1rem;
}

.small_text_position {
    position: relative;
    top: 20px;
}

.showInMobile {
    display: none;
}

.hideInDesktop {
    display: none;
}

/* ------------------------ FIND MY CAR STYLES ----------------- */

.FindMyCar_container {
    padding: 3rem 4rem;
    height: 100%;
    /* border: 1px solid red; */
    background: var(--gradient-four);
}

.findMyCar_parent {
    /* border: 1px solid blue; */
    background-color: #fff;
    border-radius: 15px;
    width: 90%;
    max-width: 1022px;
    box-shadow: var(--boxShadow);
}

.content_block-findMyCar {
    display: flex;
    justify-content: space-between;

    /* gap: 1rem; */
}

.leftContent_findMyCar {
    border-radius: 15px;
    width: 27%;
    /* border: 1px solid red; */
    max-width: 315px;
    padding: 1.5rem 1.5rem;
    background: var(--gradient-five);
}

.contentDivide_findMyCar {
    display: flex;
    flex-direction: column;
    gap: 1rem;

}

.rightContent_findMyCar {
    width: 73%;
    gap: 2rem;
    padding: 1.5rem;
    padding-bottom: 2.5rem;
}

.leftContent_findMyCar_head {
    font-size: 1rem;
    font-weight: 500;
}

.info_block_left_findMyCar {
    /* border: 1px solid red; */
    border-radius: 5px;
    word-break: break-word;
    padding: .5rem;
    cursor: pointer;
    min-height: 56px;
}

.info_block_left-active {
    box-shadow: var(--boxShadowTwo) !important;
    background-color: #fff !important;
}

.info_block_left_head {
    font-size: .8rem;
    font-weight: 600;
}

.info_block_left_desc {
    font-size: .8rem;
    font-weight: 400;
    color: var(--text_color_one);
}

.info_block_left_desc-red {
    color: var(--redTxt);
}

.heading_rightSide_findMycar {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: .3rem;
    /* border: 1px solid red; */
}

.imp-msg-fmc {
    position: absolute;
    /* bottom: -1.5rem; */
    top: 1.6rem;
}

.heading_rightSide_findMycar>:first-child {
    font-size: 1rem;
    font-weight: 500;
}

.title_brandCar {
    font-size: .9rem;
    font-weight: 500;
    /* margin-bottom: 1rem; */
}

.brandLogoContainer-findMyCar {
    /* border: 1px solid red; */
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
    height: 21.5rem;
    overflow: auto;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.logo-findmycar {
    height: 22rem;
}

.model-findmycar {
    height: 21rem;
}

.brandLogoContainer-input {
    position: relative;
    /* margin-bottom: 1rem; */
}

.search-fmc {
    position: absolute;
    z-index: 1;
    top: 1.10rem;
    left: 1rem;
}

.brandContainer-findMyCar {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    max-width: 690px;
    position: relative;
    /* border: 1px solid red; */
}

.fmcInput {
    border-radius: 5px !important;
    padding-left: 2.4rem !important;
}

.fmcInput:focus {
    border: 1px solid var(--primary);
}

.fmc-btn {
    height: 52px;
    width: 7rem;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    right: 0;
    font-weight: 500;
}

.logosWrapper-fmc {
    /* border: 1px solid red; */
    padding: .5rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    flex-wrap: wrap;
}

.logosWrapper-fmc-makeList {
    padding-top: 1.5rem;
}

.logosWrapper-fmc-model {
    gap: 2rem;
}

.model_tab {
    height: 50px;
    border: 1px solid var(--border_color_three);
    border-radius: 5px;
    cursor: pointer;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    padding: 0px 5px;
    word-break: break-word;
    transition: all .1s ease-in;
    background-color: #fff;
}

.model_tab-active {
    background-color: var(--border_color_three);
}

.model_tab:hover {
    background-color: var(--border_color_three);
}

.img_square-fmc {
    /* background-color: #76c9f9; */
    /* border: 1px solid blue; */
    width: 7rem;
    display: flex;
    padding: 5px 5px;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    filter: grayscale(100%);
    gap: .5rem;
    transition: all .1s ease-in-out;
    cursor: pointer;
    /* background-color: var(--border_color_three); */
}

.img_square-fmc:hover {
    filter: grayscale(0%);
    background-color: var(--border_color_three);
}

.img_square-fmc-active {
    background-color: var(--border_color_three);
    filter: grayscale(0%);
}

.img_square-fmc>p {
    font-size: .72rem;
    color: var(--text_color_one);
    font-weight: 400;
}

.btnContainer-fmc-noPadding {
    padding-top: 0rem !important;
}

.btnContainer-fmc {
    /* border: 1px solid red; */
    height: 4rem;
    padding-top: 1rem;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
    /* margin: 1rem 0rem; */
}

.secondry_btn-fmc {
    height: 3.5rem;
    border-radius: 5px;
    width: 12rem;
}

.fuelTypeContainer-findMyCar {
    height: 18rem;
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    align-items: flex-start;
    align-content: flex-start;
}

.fuelTypeContainer-findMyCar- {
    height: 27rem;
}

.fuelType-btn {
    min-width: 107px;
    height: 50px;
    background-color: #fff;
    padding: 0px 10px;
    color: var(--text_color_one);
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
    outline: none;
    border: 1px solid var(--border_color_three);
}

.fuelType-btn-active {
    background-color: var(--border_color_three);
}

.fuelType-btn:hover {
    background-color: var(--border_color_three);
}

.input_group-FMC {
    width: 100%;
}

.hide {
    display: none;
}

.show {
    display: flex !important;
}

.policyDetails-container {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 27.5rem;
}

.logo-findmycarTwo {
    height: 22rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.logo-findmycar-padding {
    padding-bottom: 2rem;
}

.fmc-btn-container {
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: auto;
    transition: all .2s ease-in;
    align-items: flex-start;
}

.fmc-btn-container-varient {
    padding-left: 1.5rem;
    justify-content: flex-start;
    gap: 2rem;
}

.blurEffect {
    filter: blur(2px);
}

.height-brandContainer-findMyCar {
    min-height: 530px;
}

.mobile-back-fmc {
    display: none;
}

.btnContainer-fmc-mobile {
    display: none;
}

.bottom-text-modal-inp {
    color: var(--text_color_one);
    font-size: .85rem;
}

.mobile-product-btn {
    display: none;
    width: 100% !important;
    padding-bottom: 0 !important;
    padding-left: 1rem !important;
}

.mobile-product-btn>img {
    width: 39px;
    height: 30.487px;
}

.flex-text {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

/* .getRegNo_form_part_two-creative {
    border: 1px solid red;
} */

.skeleton-container-fmc {
    /* border: 1px solid red; */
    display: flex;
    gap: 1.3rem;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 1rem;
    width: 100%;
    overflow: hidden;
}

.skeleton-container-fmc>* {
    flex: 1 1;
    height: 50px;
    width: 30%;
    min-width: 195px;
}

.css-1e1x4vv-MuiSkeleton-root {
    border-radius: 3px;
    background-color: #d6e0f9cb !important;
}

.skeleton-container-fmc-border {
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
}

.mobile-product-container {
    /* border: 1px solid red; */
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.back-mob-prod {
    /* border: 1px solid blue; */
    display: flex;
    gap: 10px;
}

.back-mob-prod>p {
    color: var(--primary);
    font-weight: 500;
}

.mobile-prod-btn {
    /* border: 1px solid red; */
    display: flex;
    gap: 10px;
}

.phone-redirect-tab {
    border: 1px solid var(--background-colorThree);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
    border-radius: 5px;
    gap: 5px;
}

.phone-redirect-tab>p:first-child {
    color: var(--text_color_one);
    font-size: 1rem;
}

.phone-redirect-tab>p:last-child {
    color: var(--primary);
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.mobile-product-container>p {
    font-weight: 600;
}

.ui-tab-container {
    /* border: 1px solid blue; */
    min-height: 93px;
    border-radius: 5px;
    background: var(--gradient-eighteen);
    padding: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.img-container-uiTab {
    /* border: 1px solid red; */
    position: relative;
    width: 180px;
    height: 4rem;
    margin-left: -10px;
}

.img-container-uiTab>img {
    position: absolute;
}

.swiper-mob-container {
    width: 100%;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.tab-content {
    font-weight: 400;
    letter-spacing: 0.13px;
    color: var(--text_color_three);
}

.tab-content>span {
    font-weight: 600;
}

.tab-btn {
    width: 14rem;
    height: fit-content;
    padding: 10px 0px;
}

.variant_tab_fmc {
    flex-direction: row-reverse;
    gap: 5px;
    height: 63px;
}

.variant_tab_fmc>p>span {
    color: var(--primary);
    font-weight: 600;
}

.find-my-car-mob-container {
    display: none;
}

.content-block-fmv {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin: -1rem -1rem 0rem -1rem;
    padding: 1rem;
    background: linear-gradient(169deg, rgba(255, 255, 255, 0.00) -1.71%, #DFE9FF 107.73%);
}

.content-block-fmv-fuel {
    padding-bottom: 3rem;
}

.content-block-fmv>img {
    width: 1.5rem;
}

.content-block-fmv>h2 {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    /* margin: auto; */
}

.content-block-fmv>p {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: var(--primary);
    margin-bottom: 1rem;
}

.content-block-fmv-tab {
    /* border: 1px solid red; */
    padding: 1rem 0rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: space-between;
    padding-top: .3rem;
    position: relative;
    padding-bottom: 53px;
    bottom: 1.5rem;
}

.content-block-fmv-tab-fuel {
    padding-bottom: 3rem;
    flex-direction: column;
}

.mob-fmv-btn {
    height: 45px;
    width: 47%;
    background-color: #fff;
    border-radius: 5px;
    font-weight: 500;
    color: var(--text_color_three);
    border: 1px solid var(--border_color_two);
}

.mob-fmv-btn-fuel {
    width: 80%;
    margin: auto;
}

.mob-fmv-btn-fuel>p>span {
    color: var(--primary);
}

.mob-fmv-btn-rto {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    margin: auto;
}

.mob-fmv-btn-rto>p {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.toggle-fmv {
    border: 1px solid var(--border_color_two);
    margin: auto;
    width: 150px;
    height: 45px;
    color: var(--primary);
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.button-container-fmv {
    /* border: 1px solid blue; */
    width: 100%;
    padding: 1rem 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background-color: #E2EBFF;
    gap: 1rem;
    border-radius: 20px 20px 0px 0px;
}

.button-container-fmv>* {
    flex: 1 1;
    height: 45px;
    border-radius: 5px;
}

.btn-fmv-primary {
    border: 1px solid var(--primary);
    background-color: var(--primary);
    color: #FFF;
}

.btn-fmv-secondry {
    border: 1px solid var(--primary);
}

.mob-fmv-btn-active {
    background-color: var(--border_color_three);
}

.inputs-parent-mob-modal>p {
    font-weight: 500;
}

.heading-edit-modal {
    color: var(--text_color_three);
    font-weight: 500;
}

.fuelList_loader_width {
    width: 80%;
    margin: auto;
}

/* RESPONSIVENESS--------------------------------------------------------- */

@media only screen and (max-width: 907px) {

    .phone-tabs-block {
        /* border: 1px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        flex-direction: column;
    }

    .phone-tabs-block>p {
        color: var(--text_color_one);
    }

    .width {
        display: none;
    }

    .prod_btn-mobile-view {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .hideInDesktop {
        display: flex;
    }

    .mobile-product-btn {
        border-radius: 20px 20px 0px 0px !important;
        display: flex;
    }

    /* .getRegNo_form_part_one {
        width: 100%;
        position: relative;
        z-index: 1;
        top: 21%;
        left: 1.5%;
        background: #fff;
    } */

    .getRegNo_form_part_one {
        width: 100%;
        position: relative;
        /* z-index: 1; */
        top: 21%;
        /* margin: 0rem 1rem; */
        /* padding: 0rem 1rem; */
        margin: auto;
        border-radius: 0px 0px 10px 10px;
        /* left: 1.5%; */
        background: #fff;
    }

    .getRegNo_form_inp_parts {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .hideInMobile {
        display: none !important;
    }

    .showInMobile {
        display: flex;
    }

    .getRegNo_form {
        background-color: transparent;
        box-shadow: none;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .product_selction_getregno {
        /* border: 1px solid red; */
        position: unset;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .prod_btn {
        /* flex-direction: row; */
        gap: 10px;
        min-width: unset !important;
        width: 46%;
        border-radius: 10px;
        /* border: 1px solid red; */
        height: 5rem;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 1rem;

    }

    .prod_btn-fullWidth {
        /* border: 1px solid red; */
        width: 100%;
        border-radius: 10px 10px 0px 0px;
    }

    .getRegNo_container {
        padding: 1rem 0rem;
        overflow: hidden;
    }

    .prod_btn>div>span {
        color: var(--primary);
    }

    .getRegNo_form {
        padding: 0rem 4rem;
    }

    .irdai_container_head_mobile {
        padding: 1rem;
        align-items: center;
        justify-content: flex-start;
        margin-top: -1rem;
    }

}

@media only screen and (max-width: 280px) {
    /* .getRegNo_form_part_one {
        top: 17%;
    } */

    .mobile_lady_div>img {
        right: -3rem;
        display: none;
    }
}

@media only screen and (max-width: 360px) {
    .input_container_content {
        flex-direction: column !important;
    }
}

@media only screen and (max-width: 610px) {

    .mob-fmv-btn {
        font-size: .9rem;
    }

    .find-my-car-mob-container {
        display: flex;
        padding: 1rem;
        flex-direction: column;
    }

    .fmv-car-bg {
        background-size: 100% 22.5dvh;
    }

    .fmv-car-bg2 {
        background-size: 100% 31dvh;
    }

    .fmv-car-Nobg {
        background-size: 100% 0;
    }

    .Hide_FindMyCar_container {
        display: none;
    }

    .getRegNo_container {
        background: var(--gradient-seventeen);
    }

    .width {
        /* width: 100%; */
        background: #fff;
        padding: 0.5rem;
        margin-top: -1rem;
        border-radius: 15px;
        margin-bottom: 2rem;
        display: none !important;
    }

    .width-mobile {
        /* width: 100%; */
        background: #fff;
        padding: 0.5rem;
        margin-top: -1rem;
        border-radius: 15px;
        margin-bottom: 2rem;

    }

    .inp-container-mobile {
        margin-bottom: .5rem;
    }

    .getRegNo_form_inp_container {
        gap: 1rem;
        padding: 0;
    }

    .getRegNo_form {
        gap: 2rem;
        padding: 0rem 1rem;
    }

    .irdai_container_head {
        gap: 1.5rem;
        padding-bottom: 0;
        padding-top: 0;
    }

    .input_container_btn>* {
        flex: none;
        width: 100%;
    }

    .small_text_position {
        top: 5px;
    }

    .getRegNo_form_head {
        display: none;
        font-size: 1.3rem;
    }

    .bottom_getRegno_mobile {
        display: none;
    }

    .input_container {
        width: 100%;
    }

    .input_container_content {
        width: 100%;
        gap: .3rem;
        display: flex;
        flex-direction: column;
    }

    .getRegNo_form_inp_parts {
        gap: 1rem;
    }

    .bottom_getRegno_ {
        flex-direction: column;
    }

    .FindMyCar_container {
        padding: 0rem 0rem !important;
    }

    .leftContent_findMyCar {
        display: none;
    }

    .rightContent_findMyCar {
        /* border: 1px solid red; */
        gap: .5rem;
        width: 100%;
    }

    .img_square-fmc {
        width: 5rem;
    }

    .logosWrapper-fmc {
        overflow: hidden;
    }

    .brandLogoContainer-findMyCar {
        height: 25.5rem;
    }

    .heading_rightSide_findMycar {
        display: none;
    }

    .mobile-back-fmc {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        /* border: 1px solid red; */
        gap: .6rem;
        font-size: .9rem;
        color: var(--primary);
    }

    .fmc-btn-container-varient {
        justify-content: center;
        gap: .9rem;
        /* border: 1px solid red; */
        padding: 1rem 0.5rem;
    }

    .variant_tab_fmc {
        width: 47%;
    }

    .btnContainer-fmc-mobile {
        display: flex;
    }

    .input_group-FMC {
        gap: 1rem !important;
    }

    .flexDiv-findMyCar {
        padding: 1rem;
    }

    .btnContainer-fmc-rto {
        /* border: 1px solid red; */
        height: auto;
        gap: 1rem;
        flex-direction: column;
        width: 100%;
    }

    .secondry_btn-fmc-rto {
        width: 100%;
    }

    .height-brandContainer-findMyCar {
        min-height: 0;
    }

    .findMyCar_parent {
        width: 100%;
        border-radius: 0px;
        box-shadow: none;
    }

    .skeleton-container-fmc {
        padding: 0rem;
        position: relative;
        bottom: 2rem;
    }

    .skeleton-container-fmc>* {
        min-width: 150px;
    }

    .skeleton-container-fuelList>* {
        min-width: 200px;
    }

}

@media only screen and (max-width: 700px) {
    .FindMyCar_container {
        padding: 3rem 1rem;
    }
}

@media only screen and (max-width: 390px) {
    .img_square-fmc {
        width: 4rem !important;
    }

    .fmc-btn-search {
        width: 4.5rem;
    }

    .model_tab {
        width: 45%;
    }
}