/* Hide the default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  display: flex;
  width: fit-content;
}


/* Create a custom checkbox */
.checkmark {
  position: relative;
  border: 1px solid #DFE9FF;
  /*   box-shadow: rgb(255, 84, 0) 0px 0px 0px 2px; */
  /*   background-color: rgba(16, 16, 16, 0.5); */
  background-color: #F1F5FF;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container input:checked~.checkmark {
  border: 1px solid #5086FF;
  background-color: #F1F5FF;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 8px;
  top: 3px;
  width: 3px;
  height: 9px;
  border: solid #5086FF;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
  transition: all 500ms ease-in-out;
}

.viewPlan_checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.viewPlan_checkbox {
  display: flex;
  /* gap: 10px; */
  width: fit-content;
}

.viewPlan_checkbox input:checked~.checkmark {
  border: 1px solid #5086FF;
  background-color: #F1F5FF;
  height: 22px;
  width: 22px;
  margin-right: 10px;
  flex-shrink: 0;
  margin-top: -1px;
  transition: all 0.2s ease 0s;
  cursor: pointer;
  transform-origin: 0px 10px;
  border-radius: 4px;
  margin: -1px 10px 0px 0px;
  padding: 0px;
  box-sizing: border-box;
}

.viewPlan_checkbox input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.viewPlan_checkbox .checkmark:after {
  left: 0.58em;
  top: 0.28em;
  width: 0.35em;
  height: 0.65em;
  border: solid #5086FF;
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
  transition: all 500ms ease-in-out;
}

.flex-label {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  /* border: 1px solid red; */
  width: 100%;
}

.accordian-checkbox-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s cubic-bezier(0.79, 0.33, 0.14, 0.53);
  /* transition: max-height .3s ease-in-out; */
}

.accordian-checkbox-content.accordian-checkbox-content-active {
  max-height: 1000px;
}

.accordian-checkbox-head {
  display: flex;
  align-items: center;
  gap: 10px;
}

.accordian-checkbox-content>p {
  color: var(--text_color_one);
  font-size: .65rem;
  padding-left: 28px;
  padding-top: 5px;
}

.accordian-checkbox {
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}

.arrow-icon-container>img {
  width: 15px;
  padding-bottom: 6px;
  transition: rotate .3s cubic-bezier(0.79, 0.33, 0.14, 0.53);
}

.arrow-icon-container-active>img {
  rotate: 90deg;
}

.activeLabel {
  color: var(--primary);
  font-weight: 600;
  font-size: .75rem;
}

.inActiveLabel {
  color: var(--text_color_one);
  font-weight: 600;
  font-size: .75rem;
}

.btn-checkbox {
  border: 1px solid var(--border_color_two);
  background-color: var(--background-colorNine);
  color: var(--primary);
  width: 85.143px;
  height: 30px;
  border-radius: 4px;
}

@media only screen and (max-width: 610px) {
  .checkBox-label {
    font-size: .8rem !important;
  }
}