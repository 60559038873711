.PropsalFormContainer {
    padding: 3rem 4rem;
    position: relative;
    padding-top: 0rem;
    background-image: url('../../Assets/Images/Background.jpg');
    background-repeat: no-repeat;
    background-size: 100% 20rem;
}

.PropsalFormContainer_padding {
    padding-top: 2rem;
}

.PropsalFormContainer_parent {
    /* border: 1px solid blue; */
    display: flex;
    box-shadow: var(--boxShadow);
    border-radius: 15px;
    background-color: #fff;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.footer_bottom_container_pForm {
    margin-bottom: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.padding_PropsalFormContainer_parent {
    padding: 1rem 2rem;
    flex-direction: column;
}

.breakupContainer {
    width: 29%;
    max-width: 310px;
    display: flex;
    border-radius: 15px;
    background: var(--gradient-nine);
    /* border: 1px solid red; */
}

.formContainer {
    width: 76%;
    padding: 1rem 2rem;
}

.bg {
    /* border: 1px solid blue; */
    height: 50%;
    position: absolute;
}

.stepperContainer {
    /* border: 1px solid green; */
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
    padding-left: 0rem;
}

.stepperDiv {
    /* border: 1px solid red; */
    background-color: var(--background-color);
    cursor: pointer;
    padding: .5rem;
    display: flex;
    gap: 1rem;
    min-width: 11rem;
    /* border: 1px solid SILVER; */
    box-shadow: var(--boxShadowTwo);
    border-radius: 10px;
}

.stepperDiv_active {
    background-color: #fff;
}

.signal_pform {
    /* border: 1px solid blue; */
    width: 2rem;
    color: var(--text_color_three);
    display: flex;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    background-color: var(--border_color_three);
}

.small_text_stepper {
    font-size: .75rem;
}

.title_stepper>p:last-child {
    font-size: .8rem;
    color: var(--text_color_three);
}

.premiumContainer {
    padding: 14px 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border: 1px solid; */
    width: 100%;
    border-radius: 15px;
    background: var(--gradient-nine);
    /* min-height: 600px; */
}

.pol-details-premiumContainer {
    /* border: 1px solid red; */
    background-color: #fff;
    border-radius: 5px;
    /* border: 1px solid blue; */
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.PlanNameInPremiumContainer {
    border-radius: 3px;
    background: var(--Primary-Blue, #5086FF);
    margin-top: 5px;
    margin-bottom: 5px;

}

.TataPlanText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    padding: 2px 8px 2px 8px;
    font-style: normal;
    font-weight: 400;
}

.car-details-premContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    /* justify-content: space-between; */
    align-items: center;
    border-bottom: 1px solid var(--border_color_three);
    padding: 10px 0px;
    padding-top: 5px;
}

.car-details-premContainer>p:first-child {
    background-color: var(--border_color_three);
    padding: 2px 5px;
    /* font-size: .7rem; */
    border-radius: 5px;
    color: var(--text_color_four);
    font-weight: 500;
}

.car-details-premContainer>p:last-child {
    color: var(--text_color_four);
    font-weight: 500;
}

.policy-det-premContent {
    display: flex;
    gap: 2rem;
    /* justify-content: space-between; */
}

.mmv_premiumContainer {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
    padding-top: 0px;
    border-bottom: 1px solid var(--border_color_three);
    /* gap: 5px; */
}

.policy-det-premContent-block>p:first-child {
    color: var(--text_color_one);
    font-weight: 500;
}

.policy-det-premContent-block>p:last-child {
    color: var(--text_color_three);
    font-weight: 500;
}

.mmv_premiumContainer>p:first-child {
    font-size: 1rem;
    font-weight: 600;
    color: #000;
}

.mmv_premiumContainer>p:last-child {
    font-size: .8rem;
    font-weight: 500;
    color: var(--text_color_three);
}

.amountContainer {
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap: 5px;
    padding: 5px 10px;
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
}

.amountContainer>img {
    width: 4.2rem;
}

.amt_prem>:last-child {
    font-size: .7rem;
}

.amt_prem>:first-child {
    font-size: 1rem;
    font-weight: 800;
}

.amt_prem {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.breakup_headValue {
    display: flex;
    justify-content: space-between;
    gap: 3px;
    align-items: center;
}

.breakup_headValue>p:first-child {
    width: 70%;
    color: var(--text_color_one);
    font-size: .8rem;
    word-break: break-word;
}

.breakup_headValue>p:last-child {
    width: 30%;
    font-size: .8rem;
    text-align: right;
    word-break: break-word;
    color: var(--text_color_three);
}

.titleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.titleContainer_pform {
    margin-bottom: 2rem;
}

.titleContainer>p:first-child {
    font-size: .9rem;
    color: #000 !important;
    font-weight: 500;
}

.titleContainer>p:last-child {
    font-size: .8rem;
    color: var(--redTxt);
}

.checkBox_form {
    padding: 2rem 0rem;
    padding-bottom: 0rem;
}

.input_group_pform {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
    position: relative;
}

.marginForTata {
    margin-top: -2rem;
}

.input_group_pform2 {
    /* display: flex; */
    gap: 2rem;
    position: relative;
}

.input_group_pform-unique-kyc {
    display: flex;
    gap: 2rem;
    position: relative;
}

.input_group_pform>* {
    flex: 1 1;
}

.formSection-pform {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.kyc_info_container {
    /* border: 1px solid var(--primary); */
    padding: 1rem 1rem;
    padding-right: 2rem;
    /* background: var(--gradient-one); */
    background: linear-gradient(159deg, #E3E4FF -12.5%, #FEE6E7 139.53%);
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    gap: 1rem;
}

#dealer_info_container {
    gap: 0px
}

.Email_Phone_Alert_Message {
    color: var(--Primary-Blue, #BF9A77);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Deler_alert_container_content {
    width: 100%
}

.btn_pForm {
    width: 11rem;
    height: 3.2rem;
    margin-top: 1rem;
}

.btnContainer_pform {
    display: flex;
    justify-content: flex-start;
    gap: 1.5rem;
    padding-bottom: 2rem;
}

.kyc_container_photo_conteent {
    /* border: 1px solid red; */
    display: flex;
    gap: 1.5rem;
    position: relative;
    padding-bottom: 2rem;
    justify-content: space-between;
}

.bottomInfo_kyc {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: .8rem;
    color: var(--text_color_three);
}

.bottomInfo_kyc-two {
    font-size: .8rem;
    color: var(--text_color_three);
}

.kyc_container_content {
    width: 80%;
}

.kycFormContainer {
    display: flex;
    /* width: 75%; */
    flex-direction: column;
    padding: 1rem 0rem;
    gap: 2rem;
    /* border: 1px solid blue; */
}

.title_kyc_pf {
    font-size: .9rem;
    font-weight: 500;
}

.title_kyc {
    /* here i edit */
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 500;
    line-height: 0.5px;
    width: 138px;
    height: 23px;
    top: 322px;

}

.inputGropKyc-custom_acko_kyc {
    padding: 2rem;
    border-radius: 10px;
    border: 2px dashed rgba(80, 134, 255, 0.50);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    background: #FFF;
}




.btn-kyc_acko {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.customLabel-kyc_acko2 {
    height: fit-content;
    width: 80%;
    background-color: var(--primary);
    background-color: #F1F5FF;
    border: 1px solid #B1C9FF;
    color: #5086FF;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    /* color: ; */
}

.textbld {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    margin-bottom: 10px;
    /* width: 157px; */
    height: 23px;
    top: 427px;
    color: #000000;
}


/* Style for the upload button */
/* .upload-btn {
        background-color: #3A84FF;
        color: #fff;
        padding: 10px 30px;
        border: none;
        border-radius: 25px;
        font-size: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
    } */

/* INPUT field for icici design change   29/8/24 */
.acko_kyc_input {
    border: 1px solid var(--border_color_two);
    height: 50px;
    width: 100%;
    border-radius: 15px;
    color: var(--text_color_three);
    font-weight: 500;
    letter-spacing: 14.46px;
    text-transform: lowercase;
    padding: 5px;
    text-align: center;
}

.acko_kyc_input:focus {
    border: 1px solid var(--primary);
    outline: 1px solid var(--primary);
}

.acko_kyc_input::placeholder {
    color: var(--text_color_two);
    font-weight: 500;
    letter-spacing: 14.46px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-container {
    border: 1px solid red;
    margin-top: 4px;
    /* width: 433px; */
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #5086FF80;
    text-align: center;
    color: #8BB6F9;
    border-radius: 10px;
    border: 2px dashed rgba(80, 134, 255, 0.50);
    background: #F1F5FF;
    cursor: pointer;
}

.mg-fix {
    margin-bottom: -50px;
}

.tfsfhhf {
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;



}

/* .tfsfhhf>div:nth-child(1) {
    width: 44%;

} */

.upload-container img {
    width: 35px;
    height: 24px;
    margin-bottom: 10px;
}

.upload-container p {
    margin: 0;
    font-size: 14px;
    color: #333333;
}

.upload-container span {
    color: #999999;
    font-size: 12px;
}

.upload-container input[type="file"] {
    display: none;
}

/* after upload */
.uploadedd-container {
    margin-top: 4px;
    /* width: 433px;  */
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #5086FF80;
    text-align: center;
    color: #1A1B1D;
    font-size: 13px;
    font-weight: 500;
    border-radius: 10px;
    border: 2px dashed rgba(80, 134, 255, 0.50);
    background: #F1F5FF;
    cursor: pointer;
}

.uploadedd-container img {
    width: 35px;
    height: 37px;
    margin-bottom: 15px;
}

.text-bold {
    font-size: 14px;
    font-weight: 500;
    line-height: 23px;
    height: 20px;
    line-height: 22.5px;
    margin-top: -5px;
    color: #1A1B1D
}

.uploadedd-container>p>span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: #FF464D;
}

.text-button {
    background: none;
    border: none;
    color: blue;
    /* Change this to the desired text color */
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
}

.text-button:hover {
    text-decoration: none;
    /* Optional: remove underline on hover */
}

.uploadedd-container input[type="file"] {
    display: none;
}

.uploadedd-container2 input[type="file"] {
    display: none;
}

/* here i provide a css for popup for kyc field for icici */
.main-kyc {
    width: 400px;
    height: 340px;
    border-radius: 15px;
}

.main-kyc>img {
    padding-left: 368px;
    padding-top: 11px;
}

.main-kyc2 {
    width: 400px;
    height: 364.97px;
    border-radius: 15px 0px 0px 0px;
}

.kyc-icici>img {
    padding-left: 165px;
    /* padding-top: 24px; */
}


.kyc-icici>p {
    color: #FF464D;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    padding-top: 4px;
    padding-left: 90px;
    padding-right: 90px;
}

.kyc-icici2>p {
    color: #5086FF;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    padding-top: 30px;
    padding-left: 90px;
    padding-right: 90px;

}

.text2 {
    padding-left: 183px;
    padding-top: 15px;
}

.Re-input-text {
    width: 144px;
    height: 20px;
    top: 164px;
    left: 29.12px;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: #1A1B1D
}

/* .text{
width: 144px;
height: 20px;
color: #1A1B1D;
font-size: 13px;
font-weight: 500;
line-height: 19.5px;
} */
.kyc-main-second {
    margin-top: 20px;
}

.inputfield-des {
    padding-right: 36px;
    padding-Left: 36px;
}

.inputfield-des>p {
    margin-top: 10px;
    width: 144px;
    height: 20px;
    color: #1A1B1D;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
}

.btnforKyc {
    width: 82%;
    height: 3.2rem;
    margin: auto;
    margin-top: 20px;
}

/* written for upload Model  */

.uploaded_success_container {
    /* border: 1px solid red; */
    width: 23rem;
    padding: 1rem;
    height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.uploaded_success_container>p {
    font-size: 1rem;
    text-align: center;
    color: var(--greenText);
}

.uploaded_success_container>img {
    width: 4rem;
}


.kyc_container_photo {
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 1px solid var(--primary); */
    flex-direction: column;
    cursor: pointer;
    border-radius: 5px;
    gap: 1rem;
    /* width: 20%; */
    height: 9.4rem;
    padding: 1rem;
    max-height: 148px;
    background: linear-gradient(158.86deg, #E3E4FF -12.5%, #FEE6E7 139.53%);
    /* background-color: var(--border_color_three); */
}

.kyc_container_photo-err {
    border: 1px solid red !important;
}

.kyc_container_photo_img {
    width: 20%;
    border-radius: 5px;
    height: 150px;
    aspect-ratio: 2 / 3;
}

.kyc_container_photo>img {
    width: 4.1rem;
}

.profileImg-kyc {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.kyc_container_photo>p {
    /* border: 1px solid red; */
    font-size: .8rem;
    text-align: center;
    color: var(--text_color_four);
}

.textcolo {
    width: 130px;
    height: 23px;
    top: 322px;
    left: 427.17px;
    padding-bottom: 10px;
    font-size: 15px;
    margin-bottom: 17px;
    font-weight: 500;
    line-height: 22.5px;
}

.upload {
    border-radius: 15px;
}

.inputGrop_caseTwo {
    flex-direction: column;
}

.customLabel_pform {
    font-size: .9rem;
    font-weight: 500;
    margin-bottom: .7rem;
}

.radioContainer-pform {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.nominee_division {
    display: flex;
    /* border: 1px solid red; */
    gap: 1rem;
}

.nominee_division_age {
    width: 20%;
}

.nominee_division_Relation {
    width: 80%;
}

.Input_Dropdown {
    border: 0px;
    outline: 0px;
    font-size: .8rem;
    width: 100%;
    color: var(--text_color_three);
    cursor: pointer;
}

#relParentDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer;
}

#prevInsListDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

#cityListDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

#stateParentDp {
    font-size: .9rem;
    color: var(--text_color_three);
    cursor: pointer
}

.headTxtClassPform {
    font-size: .8rem !important;
    color: var(--text_color_two) !important;

}

.inputGropKyc-custom {
    border: 1px solid var(--border_color_two);
    height: 52px;
    display: flex;
    justify-content: space-between;
    padding-left: 1rem;
    align-items: center;

    border-radius: 5px;
}

.inputGropKyc-custom>p {
    font-size: .8rem;
    color: var(--text_color_two);
    pointer-events: none;
}

.btn-kyc- {
    height: 100%;
    border: none;
    text-transform: capitalize;
    outline: none;
    color: #000;
    display: flex;
    cursor: pointer;
    font-size: .9rem;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: var(--border_color_two);
    border-radius: 5px;
}

.customLabel-kyc {
    width: 35%;
    height: 100%;
}

.input_group_pform-kyc {
    /* border: 1px solid red; */
    padding-bottom: 2.5rem;
    position: relative;
}

.input_group_pform-kyc-tata {
    padding-bottom: 0rem;
}

.msg-kyc {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: space-between;
}

.msg-kyc>* {
    flex: 1 1;
}

.input_group_pform-flex {
    gap: 3rem;
}

.inputGrop_caseThree {
    padding-bottom: 1.5rem;
}

.edit_btn_pform {
    position: absolute;
    right: 0;
}

.hideWithOpacity {
    /* opacity: 0; */
    visibility: hidden;
    /* border: 1px solid red; */
    height: 2rem;
    overflow: hidden;
}

.kyc-succes-upload {
    color: var(--greenText);
}

#kycPanNumberr {
    width: 47% !important;
}

#kycAadharNumber {
    width: 47% !important;
}

#uploadImg-Aadharr {
    width: 47% !important;
}

#uploadImg-PANn {
    width: 47% !important;
}

.gross-prem-block {
    background: #b1c9ff;
    padding: 3px 10px;
    border-radius: 5px;
    font-weight: 700;
}

.bold-content {
    font-weight: 700;
}

input[type="text"] {
    text-transform: capitalize;
}

.label-container {
    border-radius: 5px;
    width: 15%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.view-breakup-btn {
    text-align: left;
    position: relative;
    color: var(--primary);
    cursor: pointer;
}

.view-breakup-btn-non-relative {
    top: 0;
}

.breakup-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* border: 1px solid red; */
    padding: 10px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.breakup_headValue-bold>p:first-child {
    /* border: 1px solid red; */
    color: var(--text_color_one);
    font-weight: 600;
}

.breakup_headValue-bold>p:last-child {
    font-weight: 600;
}

.breakup_headValue-bold-border {
    border-bottom: 1px solid var(--border_color_three);
    padding-bottom: 5px;
}

.breakup-container-od {
    height: 0;
    overflow: hidden;
    /* display: none; */
    transition: height 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.breakup-container-od.breakup-container-od-extended {
    height: 100%;
}

.breakup-container.expand-breakup-container {
    height: 18.5rem;
    overflow: auto;
}

.PremiumContainer-div {
    width: 100%;
    display: flex;
    height: fit-content;
    position: sticky;
    top: .5rem;
}

.mobile-back-pform {
    margin-bottom: .6rem;
    position: absolute;
    top: 15px;
    left: 1rem;
}

.blank-checkBox-div {
    padding: 0;
    height: 0rem;
}

.edit_btn_pform-mobile {
    display: none;
}

.title-editbtn-container-collapsible {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.adaptive-breakup-proposal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.view-breakup-btn-adaptive {
    text-align: center;
    display: none;
    margin-bottom: 1rem;
}

/* ShreLink style */
.shareLinkBtn {
    border-radius: 5px;
    border: 1px solid var(--Blue-5, #B1C9FF);
    background: #F1F5FF;
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    cursor: pointer;
}

.paymentLinkBox_Container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.label_shareLink {
    color: #1A1B1D;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}

.shareLinkBtn>p {
    color: var(--Primary-Blue, #5086FF);
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-left: 10px;
    line-height: normal;
}

.paymentLinkBox {
    /* height: 398.93px;
    width: 457.502px; */
    padding: 1.5rem;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 50px 0px rgba(80, 134, 255, 0.20);
    min-height: 25rem;
    width: 26rem;
}

.NoQuoetFondBoxmainContainer {
    padding: 1.5rem;
    padding-bottom: 3rem;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 50px 0px rgba(80, 134, 255, 0.20);
    min-height: 18rem;
    width: 25rem;
}

.NoQuoetFondBox {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
}

.NoQuoetFondBox>div:nth-child(1) {
    margin-bottom: 0px;
}

.NoQuoetFondBox>div>img {
    width: 9rem;
}

.secodboxfornoQuoet {
    display: flex;
    gap: 1rem;
    margin: 12px;
}

/* .secodboxfornoQuoet>div:nth-child(1){
    min-height: 100%;
    min-width: 100%;
} */
.secodboxfornoQuoet>div:nth-child(2) {
    color: #FF464D;
    font-size: 17px;
    font-weight: 600;
}

.NoQuoetFondBoxboxthree {
    /* color: #60646A; */
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.NoQuoetBtn {
    /* border: 1px solid red; */
    text-align: center;
    border-radius: 5px;
    height: 50px;
    padding: 12px;
    background-color: var(--primary);
    border: none;
    color: #fff;
    cursor: pointer;
    width: 14rem;
    /* background-color: #2C5ECD; */
}

.link_Text {
    color: #000;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #E4E4E4;
    padding-bottom: 10px;
    /* margin-bottom: 10px; */
}

.btnContainer_paymentLink {
    padding-bottom: 0;
    margin-top: 10px;
}

.btnContainer_paymentLink>* {
    flex: 1 1;
}

.confirm_Container {
    min-height: 20rem;
    width: 25rem;
}

.confirmImg {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.confirmText {
    color: #23D32A;
    text-align: center;
    font-family: Poppins;
    font-size: 0.87rem;
}

.acko-creative-container {
    border-radius: 10px;
    height: 15rem;
    display: flex;
    justify-content: space-between;
    background: linear-gradient(96.21deg, #714091 0.44%, #9A67AC 65.62%, #603876 98.57%);
}

.acko-creative-img {
    height: 100%;
}

.acko-creative-img>img {
    height: 100%;
    padding-right: .5rem;
}

.acko-creative-content {
    padding: 1.3rem;
}

.acko-creative-content>p {
    color: #fff;
    font-size: 1.3rem;
    font-weight: 600;
}

.acko-logo-container {
    margin-top: 1rem;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.acko-logo-container>p {
    text-transform: uppercase;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
}

.acko-logo-creative {
    width: 6rem;
    margin-bottom: 10px;
}

.acko-logo-container>div>p {
    color: #fff;
    font-size: .9rem;
}

.download-container {
    display: flex;
    gap: 10px;
}

.download-container-img {
    color: #fff;
    align-items: center;
    display: flex;
    gap: 4px;
}

.download-container>p {
    font-weight: 500;
}

.input_group_pform-loan {
    flex-direction: column;
    /* border: 1px solid red;    */
    width: 50%;
    padding-right: 1rem;
}

.acko_kyc_input {
    border: 1px solid var(--border_color_two);
    height: 50px;
    width: 100%;
    border-radius: 15px;
    color: var(--text_color_three);
    font-weight: 500;
    letter-spacing: 18.46px;
    text-transform: lowercase;
    padding: 5px;
    text-align: center;
}

.acko_kyc_input:focus {
    border: 1px solid var(--primary);
    outline: 1px solid var(--primary);
}

.uploaded_success_container {
    /* border: 1px solid red; */
    width: 23rem;
    padding: 1rem;
    height: 22rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
}

.uploaded_success_container>p {
    font-size: 1rem;
    text-align: center;
    color: var(--greenText);
}

.uploaded_success_container>img {
    width: 4rem;
}

.customLabel-kyc_acko_active {
    background-color: var(--background-color) !important;
    color: var(--primary) !important;
    border: 1px solid var(--primary);
}

.acko_kyc_input::placeholder {
    color: var(--text_color_two);
    font-weight: 500;
    letter-spacing: 14px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label-container_kyc_Acko {
    border: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 148px;
    height: 148px;
}

.inputGrop_padding {
    padding-top: 28px;
}

.label-container_kyc_Acko>img {
    width: 100%;
    border-radius: 5px;
}

.titleContainer_acko {
    margin-bottom: 1rem;
}

.btn_kycForm_acko {
    width: 48.5%;
    height: 3.2rem;
}

.btn_kycForm_acko_loading {
    width: 48.5%;
}

/* .btn_container-loader {
    width: 100%;
} */

.acko_kyc_modal_container {
    /* border: 1px solid red; */
    width: 28rem;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.kyc_modal_msg_container {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kyc_modal_msg_container>p {
    color: var(--redTxt);
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
}

.acko_kyc_input_case_two {
    width: 100%;
}

.kyc_label_acko {
    color: var(--text_color_three);
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 10px;
}

.btn_kycForm_acko_caseTwo {
    width: 100%;
}

.or_text {
    text-align: center;
    color: var(--text_color_one);
    font-size: 14px;
}

.customLabel-kyc_acko {
    height: fit-content;
    width: 80%;
    background-color: var(--primary);
    color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.btn-kyc_acko {
    height: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.custom_acko_kyc_err {
    border: 2px dashed var(--redTxt);
}

.successFull_msg {
    color: var(--greenText);
}

.formContainer_acko {
    min-height: 30rem;
}

.input_group_pform_kyc_acko {
    /* border: 1px solid red; */
    flex-direction: column;
    width: 48.5%;
}

#custom_dob>input {
    border: 1px solid var(--border_color_two);
    height: 42px;
    border-radius: 8px;
    text-align: center;
    width: 100%;
}


#custom_dob>input:focus {
    border: 1px solid var(--primary);
    outline: 1px solid var(--primary);
}

#custom_dob::placeholder {
    color: var(--text_color_two);
    text-align: center;
}

.custom_dob_container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    /* border: 1px solid green; */
}

.custom_dob_container>* {
    width: 30%;
}

.acko_kyc_modal_container>img {
    width: 1rem;
    position: absolute;
    right: 1rem;
}

.amountContainer_acko {
    padding: 5px 15px;
}

.label_container_kyc_mob {
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    position: relative;
    padding: 15px 0px;
    border-bottom: 1px solid var(--background-colorFour);
}

.label_container_kyc_mob:last-child {
    border-bottom: 0;
}

.label_container_kyc_mob>img {
    width: 1rem;
}

.label_container_kyc_mob>span {
    position: absolute;
    right: 0;
    color: var(--primary);
    font-size: 13px;
    font-weight: 600;
}

.upload_label_container {
    /* border: 1px solid red; */
    padding: 1rem;
    padding-bottom: 2rem;
}

.inputGrop_modal_close_container {
    display: flex;
    justify-content: flex-end;
}

.err-msg-acko {
    color: var(--redTxt) !important;
}

.uploadContainer_mobile {
    display: none;
}

/* RESPONSIVENESSSSSSSS---------------------------------------------------------------- */

@media only screen and (max-width: 1174px) {
    .acko-creative-container {
        display: none;
    }
}

@media only screen and (max-width: 610px) {

    .uploadContainer_mobile {
        display: block;
    }

    .kycFormContainer {
        gap: 0.5rem;


    }

    .uploaded_success_container {
        /* border: 1px solid red; */
        width: 100%;
    }

    .btn_kycForm_acko_loading {
        width: 100%;
    }


    .formContainer_acko {
        padding-bottom: 6rem !important;
        min-height: none;
        /* border: 1px solid red; */
    }

    .footer-parent-padding-acko {
        display: none;
    }

    .titleContainer_acko {
        display: none;
    }

    .acko_kyc_modal_container {
        width: 100dvw;
    }

    .acko_kyc_modal_container {
        width: 100%;
    }

    .label-container_kyc_Acko {
        display: none;
    }

    .input_group_pform_kyc_acko {
        width: 100%;
    }

    .inputGrop_padding {
        padding-top: 0px;
    }

    .footer_bottom_containerTwo_part-mob {
        margin-bottom: 5rem;
    }

    .NoQuoetFondBoxmainContainer {
        width: 100%;
    }

    .input_group_pform-loan {
        width: 100%;
        padding-right: 0;
    }

    .car-details-premContainer {
        display: flex;
        gap: 5rem;
    }

    .policy-det-premContent {
        display: flex;
        gap: 5rem;
    }

    .title-editbtn-container-collapsible {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        /* border: 1px solid; */
        width: 100%;
        padding-right: 20px;
        align-items: center;
    }

    .footer-parent-padding {
        /* border: 1px solid red; */
        padding: 0rem 1rem;
    }

    .btn_pForm {
        width: 100%;
    }

    .premiumContainer {
        padding-bottom: 0px;
    }

    .premiumContainer_acko_kyc {
        padding-bottom: 1rem;
    }

    .collapsibleTab-column {
        flex-direction: column !important;
    }

    .edit_btn_pform-mobile {
        display: flex;
    }

    .edit_btn_pform {
        display: none;
    }

    #kycPanNumberr {
        width: 100% !important;
    }

    #kycAadharNumber {
        width: 100% !important;
    }

    .kyc_container_photo_img {
        width: 100%;
    }

    .label-container {
        width: 100%;
    }

    .kyc_container_photo_conteent {
        flex-direction: column;
        gap: 1rem;
    }

    .kyc_container_content {
        width: 100%
    }

    .stepperContainer {
        padding: 1rem;
    }

    .headTxtClassPform {
        font-size: 1rem !important;
        /* color: #5086FF ; */
    }

    .main-proposalForm-container {
        overflow: hidden;
    }

    .Input_Dropdown {
        font-size: 1rem;
    }

    .hideWithOpacity {
        display: none;
    }

    .titleContainer_pform {
        margin-bottom: 1rem;
    }

    .view-breakup-btn {
        display: block;
    }

    /* .breakup-container {
        height: 0;
        overflow: hidden;
    } */

    .formSection-pform {
        gap: 1rem;
    }

    .btnContainer_pform>* {
        flex: 1 1;
    }

    /* .stepperWithForm {
        padding-top: 2rem;
    } */

    .PropsalFormContainer_parent {
        flex-direction: column;
        margin-bottom: .5rem;
        box-shadow: none;
    }

    .PropsalFormContainer {
        background-image: none;
        padding: 1rem 0rem;
    }

    .breakupContainer {
        width: 100%;
        max-width: none;
    }

    .input_group_pform {
        flex-direction: column;
        gap: 1rem;
    }

    .formContainer {
        width: 100%;
        padding: 1rem 1rem;
        /* padding-bottom: 0; */
    }

    .paymentLinkBox {
        zoom: .9;
    }

    .shareLinkBtn {
        zoom: .8;
    }

    .titleContainer {
        flex-direction: row;
        gap: .5rem;
        align-items: center;
    }

    .checkBox_form {
        padding: 1rem 0rem !important;
        padding-bottom: 0rem !important;
    }

    .btnContainer_pform {
        padding-bottom: 0rem;
    }

    .btnContainer_pform-cnfrmDetails {
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0rem;
        background: var(--background-colorTwo);
        padding: 1rem 1rem;
        border-radius: 20px 20px 0px 0px;
    }

    /* unessa */
    .tfsfhhf {
        display: block;
        width: 100%;
        margin: auto;
    }

    .main-kyc {
        width: auto;
    }

    .tfsfhhf>div:nth-child(1) {

        width: 100%;
        margin: auto;
    }

    .uploaded_success_container {
        /* border: 1px solid red; */
        width: 100%;
    }

    .btnforKyc {
        width: 82%;
        height: 3.2rem;
        margin: auto;
        margin-top: 16px;
    }
}

@media only screen and (max-width: 405px) {
    .acko_kyc_modal_container {
        width: 111dvw;
    }
}

@media only screen and (max-width: 1038px) {
    .stepperContainer {
        pointer-events: none;
        opacity: 0;
        height: 1rem;
        /* display: none; */
    }
}