.footer_container {
    background: var(--gradient-one);
    padding: 3rem 4rem 2rem 4rem;
}

.card_footer {
    /* border: 1px solid blue; */
    /* display: none; */
    position: relative;
    padding: 2rem 2rem;
    border-radius: 20px;
    background-color: #fff;
    padding-bottom: 6rem;
    display: flex;
    justify-content: space-evenly;
    box-shadow: 0px 0px 44px 0px rgba(80, 134, 255, 0.20);
    align-items: center;
    gap: 4rem;
}

.cross_footer {
    position: absolute;
    top: 2rem;
    right: 2rem;
}

.logo_footer {
    width: 12rem;
    position: absolute;
    bottom: 1.5rem;
    right: 4rem;
}

.card_child_footer {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 20rem;
}

.footer_title {
    font-size: 1rem;
    font-weight: 550;
    color: #000;
    cursor: pointer;
}

.footer_card_content {
    font-size: .8rem;
    cursor: pointer;
    color: var(--text_color_one);
}

.menu_card_child_footer {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    word-wrap: break-word;
    padding-right: 1rem;
}


.footer_parent {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.footer_content {
    padding: 2rem;
    display: flex;
    gap: 1rem;
    padding-top: 0rem;
    border-bottom: 1px solid var(--border_color);
}

.footer_content>* {
    flex: 1 1;
}

.footer_content_child_menu {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer_content_child_menu_two {
    gap: 1rem;
}

.footer_content_child_menu>* {
    flex: 1 1;
}

.footer_content_child {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer_content_child_part {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.footer_content_child_part_two {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.footer_title_two {
    color: var(--text_color_one);
    font-size: .8rem;
    letter-spacing: .3px;
    font-weight: 550;
}

.parts_footer_content_child_two {
    display: flex;
    gap: 1rem;
}

.parts_footer_content_child>a>img {
    width: 1.5rem;
    opacity: .5;
}

.parts_footer_content_child-width {
    width: 13rem;
    /* border: 1px solid red; */
}

.footer_hyper_link {
    display: flex;
    gap: .5rem;
    font-size: .86rem;
    color: var(--primary);
    cursor: pointer;
    /* text-decoration: underline; */
}

.parts_footer_content_child_three {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.parts_footer_content_child_three>a>img {
    width: 1rem;
}

.footer_bottom_container {
    /* border: 1px solid red;/ */
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--border_color);
}

.footer_bottom_container>p {
    color: #000;
    font-size: .65rem;
}

.footer_bottom_containerTwo {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.footer_bottom_containerTwo_part>p {
    font-size: .7rem;
    color: #000;
}

/* RESPONSIVENESS */

@media only screen and (max-width: 1060px) {
    .card_footer {
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 2rem;
    }

    .footer_content_child_menu {
        flex-direction: column;
        gap: 1rem;
    }
}

@media only screen and (max-width: 740px) {
    .footer_container {
        padding: 1rem 1rem;
    }

    .card_footer {
        padding-bottom: 2rem;
    }

    .card_child_footer {
        height: auto;
    }

    .logo_footer {
        display: none;
    }
}

@media only screen and (max-width: 600px) {

    /* .footer_bottom_containerTwo_part {
        margin-bottom: 3rem;
    } */

    .footer_container {
        display: none;
    }

    .footer_parent {
        gap: 1.5rem;
    }

    .footer_bottom_container {
        padding-bottom: 1.5rem;
    }

    .footer_content {
        flex-direction: column;
        padding: 2rem 0rem;
    }

    .footer_bottom_containerTwo {
        flex-direction: column;
    }

}