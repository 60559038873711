.partners_container {
    /* border: 1px solid red; */
    padding: 3rem 4rem;
    padding-bottom: 3rem;
    padding-top: 2rem;
}

.partner_title {
    margin-bottom: 2rem;
}

.partner_logo_container {
    /* border: 1px solid blue; */
    padding: 1rem 1rem;
    border-radius: 25px;
    box-shadow: var(--boxShadow);
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    background: #fff;
    /* border: 1px solid red; */
    gap: 2rem;
}

.partner_logo_container_two {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
}

.partner_logo_container_button {
    width: 100%;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_container {
    /* border: 1px solid orange; */
    padding: 5px 10px;
    filter: grayscale(100%);
    transition: all .1s ease-in;
}

.partnersBtn {
    width: auto;
}

.logo_container:hover {
    filter: grayscale(0%);
}

.logo_container_partner {
    width: 10rem;
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
}

.logo_container_partner>img {
    width: 65%;
    aspect-ratio: 3/2;
    object-fit: contain;
}

.logo_container>img:hover {
    filter: grayscale(0%);
}

.expand_partner_container {
    height: 0;
    transition: height 1s ease-in-out;
    overflow: hidden;
}

.expand_partner_container.showAllPartners {
    height: auto;
    transition: all 1s ease-in-out;
}

.partner-btn-view {
    border: 1px solid var(--primary);
    background-color: var(--secondry);
    color: var(--primary);
    border-radius: 5px;
    width: 112px;
    height: 38px;
    bottom: 0;
    /* text-align: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: absolute; */
    cursor: pointer;
    margin-bottom: 1rem;
}


/* ***************RESPONSIVENESS******************* */

@media only screen and (max-width: 610px) {
    #hide-partner-logo {
        display: none;
    }

    .partner-btn-view{
        bottom: -18px;
    }
}

@media only screen and (max-width: 593px) {
    .partners_container {
        padding: 2rem 1rem;
    }

    .partner_logo_container {
        padding: 2rem 1rem;
        padding-bottom: 2rem;
        justify-content: space-between;
        margin-bottom: 6rem;
    }

    .logo_container_partner {
        width: 7rem;
    }
}

@media only screen and (max-width: 760px) {

    .logo_container {
        justify-content: center;
    }

}