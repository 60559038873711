.loginContainer {
    /* border: 1px solid red; */
    padding: 5% 4rem;
    justify-content: center;
    display: flex;
    background: var(--gradient-four);
}

.loginParentContainer {
    /* border: 1px solid blue; */
    padding: 1.5rem;
    border-radius: 15px;
    background-color: #fff;
    /* width: fit-content; */
    display: flex;
    gap: 2rem;
    width: 53rem;
    box-shadow: var(--boxShadow);
}

.loginParentContainer>* {
    flex: 1 1;
}

.login-child-content {
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    /* gap: 1.5rem; */
    padding: 1.5rem;
    background: var(--gradient-four);
}

.login-child-head {
    font-size: 1.2rem;
    font-weight: 500;
}

.login-child-text {
    font-size: .8rem;
    /* border: 1px solid red; */
    color: var(--text_color_one);
    /* width: 14rem; */
}
.input-err {
    border: 1px solid var(--redTxt) !important;
    /* color: var(--redTxt) !important; */
}

.login-child-text-two {
    font-size: .9rem;
    width: auto;
    /* display: flex; */
}

.login-child-text-margin {
    margin-top: .5rem;
    margin-bottom: 1.5rem;
}

.feature_container-login {
    display: flex;
    /* border: 1px solid blue; */
    border-radius: 15px;
    background-color: #fff;
    justify-content: space-between;
    padding: 1.2rem 2rem;
    gap: 1rem;
}

.content-login-left {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

}

.content-login-left>p {
    font-size: .7rem;
    text-align: center;
    color: var(--text_color_one);
}

.login-child-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 1rem;
    justify-content: center;
}

.login-btn {
    height: 52px;
}

.form-login-head {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: .5rem;
}

.footer_hyper_link-login {
    font-size: .85rem;
}

.otpInput-container {
    /* border: 1px solid red; */
    display: flex;
    gap: 2rem;
}

.otpInput-container>div {
    /* background-color: red; */
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.otpInput-container>div>input {
    height: 52px;
    width: 42px !important;
    border: 1px solid var(--border_color_two);
    border-radius: 5px;
}

.otpInput-container>div>input:focus {
    border: 1px solid var(--primary) !important;
    outline: 1px solid var(--primary) !important;
}

.footer-login-text {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
}

.line-login {
    position: absolute;
    /* top: -100%; */
    /* border: 1px solid red; */
}

.line-container-logo {
    /* border: 1px solid red; */
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.line-container-logo>img {
    width: 100%;
    height: 100%;
}

.block-login {
    border: 1.82px solid var(--background-colorThree);
    width: 63.397px;
    height: 60.67px;
    border-radius: 13.7px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* background-color: var(--background-colorThree); */
    background-color: var(--background-colorSix);
}

.block-one-login {
    top: 12%;
    left: 24%;
}

.block-two-login {
    top: 32%;
    left: 61%;
}

.block-four-login {
    top: 88%;
    left: 61%;
}

.block-three-login {
    top: 47%;
    left: 24%;
}

.line-container-logo {
    display: none;
}

.login-illustration-container {
    display: none;
}

@media only screen and (max-width: 610px) {

    .loginContainer-height {
        height: 44rem !important;
    }

    .line-container-logo {
        display: block;
    }

    .login-illustration-container {
        padding: 0rem 0rem 0rem 1rem;
        position: relative;
        /* border: 1px solid blue; */
        display: flex;
    }

    .loginContainer {
        padding: 0rem;
        background: #fff;
        height: 51rem;
        display: flex;
        align-items: flex-start;
        position: relative;
        /* border: 1px solid red; */
        flex-direction: column;
        justify-content: flex-end;
    }

    .loginParentContainer {
        flex-direction: column-reverse;
        width: 100%;
        padding: 1rem;
        border-radius: 0px;
        height: fit-content;
        gap: 1rem;
        /* border: 1px solid red; */
        box-shadow: none;
        background-color: var(--background-color);
    }

    .login-child-form {
        padding: 0rem;
        gap: 1rem;
    }

    .form-login-head {
        margin-bottom: 0rem;
    }

    .login-child-content {
        padding: 0rem;
    }

    .login-child-head-hide {
        display: none;
    }

    .login-child-text-margin {
        display: none;
    }

    .feature_container-login {
        /* margin: 0rem -1.5rem -1.5rem -1.5rem; */
        /* border: 1px solid red; */
        background-color: #fff;
    }

    .hideInMob-login {
        display: none;
    }

    .footer-login-text {
        flex-direction: column;
        align-items: center;
    }

    .login-child-text-three {
        display: flex;
        gap: 1rem;
    }
}