.policy-cancellation-content>p {
    width: 80%;
    font-size: .85rem;
    color: var(--text_color_one);
}

.policy-cancellation-content>p>span {
    color: var(--text_color_one);
    font-weight: 500;
    font-size: 1.2rem;

}

.page-container-hero-policyCancellation>img {
    width: 22rem;
}

.policyCancellation-content>p {
    color: var(--text_color_three);
    font-size: 1rem;
}

.policyCancellation-content>span {
    color: var(--text_color_three);
    font-weight: 600;
    /* padding-top: 1rem; */
    position: relative;
    top: 1rem;
    font-size: .9rem;
}

.policyCancellation-block-content {
    margin: 1rem 0rem;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.policyCancellation-block-content>p {
    font-size: 1rem;
    font-weight: 500;
}

.policyCancellation-block-content-points {
    /* border: 1px solid blue; */
    display: flex;
    gap: 10px;
    color: var(--text_color_one);
}

.termsAndConditioncontainer {
    /* border: 1px solid red; */
    position: relative;
    top: 2rem;
}

.refund_flex {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
}